/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { Modal, Form, message } from 'antd';
import { useParams } from 'react-router-dom';
import Filter from '@/components/filter';
import { ConversationsPreview } from '@/components/conversations/preview';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { subtopics, topics } from '@/api';
import { transformCustomFieldToPost } from '@/api/conversations';
import { useModalStore } from '@/store/zustand/useModalStore';
import { TopicForm } from '../discovery/TopicForm';
import useTopic from './hooks/useTopic';
import useSubtopic from './hooks/useSubtopic';
import { useFilters } from '../filter/context';

export default function EditModal({
  visible, onCancel,
}) {
  const { topicId, subtopicId } = useParams();
  const { payload } = useModalStore();
  const queryClient = useQueryClient();
  const { data: topic } = useTopic(topicId, undefined, { enabled: !!topicId });
  const { data: subtopic } = useSubtopic(subtopicId);
  const data = subtopic || topic || payload;

  const { filters } = useFilters();

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      name: data.name,
      groups: data.groups?.map((group) => group.name),
      follow_exclusions: data.follow_exclusions,
    });
  }, [visible, topic, subtopic, payload]);

  const saveMutation = useMutation({
    mutationKey: [subtopicId || payload?.subtopicId ? 'subtopic_update' : 'topic_update', subtopicId || topicId],
    mutationFn: ({ name, follow_exclusions, groups: selectedGroup }) => {
      const { custom_fields, ...rest } = filters;
      if (subtopicId || payload?.subtopicId) {
        const { data } = subtopics.updateSubtopic({
          id: subtopicId || payload?.subtopicId,
          name,
          topics: [topicId],
          custom_fields: transformCustomFieldToPost(custom_fields),
          follow_exclusions: !!follow_exclusions,
          ...rest,
        });

        return data;
      }

      const { data } = topics.updateTopic({
        id: topicId || payload?.topicId,
        name,
        groups: [typeof selectedGroup === 'string' ? selectedGroup : selectedGroup[0]],
        custom_fields: transformCustomFieldToPost(custom_fields),
        follow_exclusions: !!follow_exclusions,
        ...rest,
      });

      return data;
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['topic'] });
      queryClient.invalidateQueries({ queryKey: ['subtopic'] });
      queryClient.invalidateQueries({ queryKey: ['topic_graph'] });
      queryClient.invalidateQueries({ queryKey: ['conversations'] });
      message.success(`${subtopicId ? 'Sub-segment' : 'Segment'} updated successfully`, 1.5);
      onCancel();
    },
    onError: (error) => {
      message.error(error.message, 1.5);
    },
  });

  const handleSave = async () => {
    const values = await form.validateFields();
    saveMutation.mutate(values);
  };

  return (
    <Modal
      className="cz-edit-modal"
      title={`${subtopicId ? 'Edit sub-segment' : 'Edit segment'}`}
      visible={visible}
      onCancel={onCancel}
      closeIcon={false}
      okText="Save"
      okButtonProps={{
        loading: saveMutation.isLoading,
        disabled: saveMutation.isLoading,
      }}
      onOk={() => handleSave()}
      width="64%"
      destroyOnClose
    >
      <div className="flex gap-3">
        <section className="flex flex-col gap-3 w-1/3 min-h-full">
          <Filter className="flex-1">
            <Filter.View>
              <Filter.AddButton className="m-3" />
              <Filter.List />
            </Filter.View>
          </Filter>
          <div className="bg-white rounded-lg p-4 pb-6">
            <TopicForm
              form={form}
              labels
              hasGroups={topicId && !subtopicId}
            />
          </div>
        </section>

        <ConversationsPreview />
      </div>
    </Modal>
  );
}
