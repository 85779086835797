/* eslint-disable camelcase */
import { buildQuery } from '@/helpers/filters';
import moment from 'moment';
import axios from './axios';
import auth from './auth';

export const transformCustomFieldToGet = (customFields) => {
  const custom_field_id = [];
  const custom_field_value = [];

  for (let i = 0; i < customFields.length; i++) {
    if (customFields[i].values) {
      for (let j = 0; j <= customFields[i].values.length; j++) {
        if (customFields[i].values[j]) {
          custom_field_id.push(customFields[i].id);
          if (customFields[i].data_type === 'date') {
            const { start, end } = customFields[i].values[j].split(' - ');
            const formatedStart = moment(start).startOf('day').toISOString();
            const formatedEnd = moment(end).endOf('day').toISOString();

            custom_field_value.push(JSON.stringify({ start: formatedStart, end: formatedEnd }));
          } else {
            custom_field_value.push(JSON.stringify(customFields[i].values[j]));
          }
        }
      }
    }
  }

  return {
    custom_field_id,
    custom_field_value,
  };
};

export const transformCustomFieldToPost = (customFields) => {
  const res = [];

  if (customFields) {
    for (let i = 0; i < customFields.length; i++) {
      for (let j = 0; j <= customFields[i].values.length; j++) {
        if (customFields[i].values[j]) {
          const item = {
            id: customFields[i].id,
          };

          if (customFields[i].data_type === 'date') {
            const { start, end } = customFields[i].values[j].split(' - ');
            const formatedStart = moment(start).startOf('day').toISOString();
            const formatedEnd = moment(end).endOf('day').toISOString();

            item.value = JSON.stringify({ start: formatedStart, end: formatedEnd });
          } else {
            item.value = JSON.stringify(customFields[i].values[j]);
          }

          res.push(item);
        }
      }
    }
  }

  return res;
};

const getConversations = async ({
  custom_fields, sources, labels, ...filters
}, perPage, page = 1, params) => {
  let newFilters = {
    ...filters,
    sources: JSON.stringify(sources),
    labels: labels ? labels.map((label) => {
      if (typeof label === 'object') {
        return label.id;
      }

      return label;
    }) : undefined,
  };
  if (custom_fields) {
    newFilters = {
      ...newFilters,
      ...transformCustomFieldToGet(custom_fields),
    };
  }

  const response = await axios.get(
    buildQuery(
      '/api/conversations',
      newFilters,
      {
        team_id: auth.team().id,
        page,
        per_page: perPage,
        ...params,
      },
    ),
  );

  return response.data;
};

const getConversation = async (id, filters, params) => {
  const response = await axios.get(
    buildQuery(
      `/api/conversations/${id}`,
      filters,
      {
        team_id: auth.team().id,
        ...params,
      },
    ),
  );

  return response.data;
};

const updateConversation = async (conversation) => {
  const response = await axios.put(`/api/conversations/${conversation.id}?team_id=${auth.team().id}`, conversation);
  return response;
};

const updateSentiment = async (conversationId, sentiment) => {
  const response = await axios.put(`/api/conversations/${conversationId}/sentiment?team_id=${auth.team().id}`, {
    sentiment,
  });
  return response;
};

const updateTopic = async (conversationId, topicIds) => {
  const response = await axios.put(`/api/conversations/${conversationId}/topic?team_id=${auth.team().id}`, {
    topic_ids: topicIds,
  });
  return response;
};

const updateSubtopic = async (conversationId, subtopicIds) => {
  const response = await axios.put(`/api/conversations/${conversationId}/subtopic?team_id=${auth.team().id}`, {
    subtopic_ids: subtopicIds,
  });
  return response;
};

const alterReadStatus = async (conversationId, status) => {
  const response = await axios.put(`/api/conversations/${conversationId}/read-status?team_id=${auth.team().id}`, {
    read_status: status,
  });
  return response;
};

const deleteConversation = async (id) => {
  const response = await axios.delete(`/api/conversations/${id}?team_id=${auth.team().id}`);
  return response;
};

const getSummary = (topicId, start, end, customFields, subtopicId, keywords, sentiment, labels, excluded_keywords, sources, days, exclude_labels) => axios.post(
  '/api/summary', {
    team_id: auth.team().id,
    topic_id: topicId,
    start,
    end,
    custom_fields: customFields,
    subtopic_id: subtopicId,
    keywords,
    sentiment,
    labels,
    excluded_keywords,
    sources,
    days,
    exclude_labels,
  },
);

const getSummaryResult = (id) => axios.get(
  `/api/summary-results/${id}`, {
    params: {
      team_id: auth.team().id,
    },
  },
);

const retrySummary = (id) => axios.put(
  `/api/summary/${id}`, {
    team_id: auth.team().id,
  },
);

const assignLabel = (conversationId, labelId, action) => axios.put(`/api/conversations/${conversationId}/label/${labelId}?team_id=${auth.team().id}`, {
  action,
});

const conversations = {
  getConversations,
  getConversation,
  updateConversation,
  updateSentiment,
  updateTopic,
  updateSubtopic,
  deleteConversation,
  alterReadStatus,
  getSummary,
  getSummaryResult,
  retrySummary,
  assignLabel,
};

export default conversations;
