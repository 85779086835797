export function getSentimentsAvg(sentiment) {
  if (!sentiment) return [];

  const { positive, negative } = sentiment;
  const positiveAvg = Math.round(positive * 100);
  const negativeAvg = Math.round(negative * 100);
  const neutralAvg = Math.round((1 - (positive + negative)) * 100);

  return [
    {
      name: 'Positive', value: positiveAvg, color: 'text-[#70C3BA]', graphColor: '#5FC59A',
    },
    {
      name: 'Negative', value: negativeAvg, color: 'text-[#F17E99]', graphColor: '#F17E99',
    },
    {
      name: 'Neutral', value: neutralAvg, color: 'text-[#DFE9F1]', graphColor: '#B6CADA',
    },
  ];
}

export function getNpsAvg(sentiment) {
  if (!sentiment) return [];

  const { promoters, detractors } = sentiment;
  const positiveAvg = Math.round(promoters * 100);
  const negativeAvg = Math.round(detractors * 100);
  const neutralAvg = Math.round((1 - (promoters + detractors)) * 100);

  return [
    {
      name: 'Promoters', value: positiveAvg, color: 'text-[#70C3BA]', graphColor: '#5FC59A',
    },
    {
      name: 'Detractors', value: negativeAvg, color: 'text-[#F17E99]', graphColor: '#F17E99',
    },
    {
      name: 'Neutral', value: neutralAvg, color: 'text-[#DFE9F1]', graphColor: '#B6CADA',
    },
  ];
}

export function getCsatAvg(sentiment) {
  if (!sentiment) return [];

  const { satisfied, unsatisfied } = sentiment;
  const positiveAvg = Math.round(satisfied * 100);
  const negativeAvg = Math.round(unsatisfied * 100);
  const neutralAvg = Math.round((1 - (satisfied + unsatisfied)) * 100);

  return [
    {
      name: 'Satisfied', value: positiveAvg, color: 'text-[#70C3BA]', graphColor: '#5FC59A',
    },
    {
      name: 'Unsatisfied', value: negativeAvg, color: 'text-[#F17E99]', graphColor: '#F17E99',
    },
    {
      name: 'Neutral', value: neutralAvg, color: 'text-[#DFE9F1]', graphColor: '#B6CADA',
    },
  ];
}

export function mrrFormatter(num) {
  if (num >= 1_000_000) return `$${(num / 1_000_000).toFixed(1)}M`;
  if (num > 999 && num < 1_000_000) return `$${(num / 1000).toFixed(1)}K`;
  return `$${Math.round(num)}`;
}
