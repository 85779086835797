/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import SentimentTooltip from '../../base-components/SentimentTooltip';
import Progress from '../ui/progress';

const SentimentAndCountRow = ({
  name, sentiment, count, percent, width, color, hasPercentages, onRowClick,
}) => {
  const [rowWidth, setRowWidth] = useState(0);

  useEffect(() => {
    setRowWidth(width);
  }, [width]);

  return (
    <div className="sentiment-and-count-lines-row" onClick={onRowClick || null}>
      <h4>{name}</h4>
      <SentimentTooltip
        sentiment={sentiment}
        style={{ display: 'block', width: 124, marginTop: 8 }}
      >
        <Progress.Sentiment
          style={{ marginTop: '12px' }}
          positive={sentiment.positive}
          negative={sentiment.negative}
        />
      </SentimentTooltip>
      <div className="sentiment-and-count-lines-ratio-wrapper">
        <div className="sentiment-and-count-lines-ratio" style={{ width: `${rowWidth}%`, backgroundColor: color }}>
          <span className="count">
            <Tooltip
              title="Total conversations in selected period"
              placement="bottom"
              overlayClassName="topic-metric-tooltip"
              overlayInnerStyle={{ fontSize: 12, marginBottom: 0 }}
            >
              {count}
            </Tooltip>
          </span>
          {
            hasPercentages ? (
              <span className="percent">
                <Tooltip
                  title="The ratio of conversations compared to the total in the selected period"
                  placement="bottom"
                  overlayClassName="topic-metric-tooltip"
                  overlayInnerStyle={{ fontSize: 12, marginBottom: 0 }}
                >
                  {percent}
                  %
                </Tooltip>
              </span>
            ) : null
          }
        </div>
      </div>
    </div>
  );
};

export default SentimentAndCountRow;
