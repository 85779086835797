import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { Spin } from 'antd';

import Layout from '@/components/Layout';
import { BucketContainer } from '@/components/bucket/BucketContainer';
import useTopic from '@/components/bucket/hooks/useTopic';
import { SentimentDateFilter } from '@/components/ui/sentiment-date-filter';
import { BucketActions } from '@/components/bucket/BucketActions';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import useSubtopics from '@/components/bucket/hooks/useSubtopics';
import useSubtopic from '@/components/bucket/hooks/useSubtopic';
import EditModal from '@/components/bucket/EditModal';
import ReleaseEditModal from '@/components/bucket/ReleaseEditModal';
import { useModalStore } from '@/store/zustand/useModalStore';
import { useQueryClient } from '@tanstack/react-query';
import { FiltersProvider } from '@/components/filter/context';
import ExportButton from '@/components/ui/export-button';

export function BucketPageContainer() {
  const queryClient = useQueryClient();
  const { topicId, subtopicId } = useParams();
  const {
    data: topic, isLoading: isTopicLoading,
  } = useTopic(topicId);
  const {
    data: subtopic, isLoading: isSubtopicLoading,
  } = useSubtopic(subtopicId);
  const isLoading = subtopicId ? (isTopicLoading || isSubtopicLoading) : isTopicLoading;

  const { defaultDate } = useDefaultDateStore();
  useSubtopics(topicId, {
    start: defaultDate.start,
    end: defaultDate.end,
  });

  const { type, setModal } = useModalStore();
  const editSubject = subtopicId ? subtopic : topic;

  if (isLoading) {
    return (
      <Layout title="">
        <div className="text-center p-6">
          <Spin />
        </div>
      </Layout>
    );
  }

  function handleOk() {
    queryClient.invalidateQueries({ queryKey: ['topic_graph'] });
  }

  return (
    <Layout
      breadcrumb={<BucketActions group={topic?.groups[0].name} topic={topic?.name} subtopic={subtopic?.name} />}
      rightHeader={(
        <>
          <SentimentDateFilter />
          <ExportButton filename="clientzen-segment-export" />
        </>
      )}
    >
      <Helmet titleTemplate="%s - ClientZen" title={subtopicId ? subtopic?.name : topic?.name} />
      <BucketContainer />

      {
        type === 'edit_modal' ? (
          <FiltersProvider value={{ filters: editSubject.filters.actual, selectedFilters: editSubject.filters.applied }}>
            <EditModal visible onCancel={() => setModal(null)} />
          </FiltersProvider>
        ) : null
      }
      <ReleaseEditModal visible={type === 'edit_releases_modal'} onOk={handleOk} onCancel={() => setModal(null)} />

    </Layout>
  );
}

export default function BucketPage() {
  const location = useLocation();

  if (!location.pathname.startsWith('/topic/overview/') && !location.pathname.startsWith('/topic/research/')) {
    return <Redirect to={location.pathname.replace('/topic/', '/topic/overview/') + (location.search ? location.search : '') + (location.hash ? location.hash : '')} />;
  }

  return <BucketPageContainer />;
}
