import axios from './axios';
import auth from './auth';
import mock from './mock';
import clients from './clients';
import colleagues from './colleagues';
import insights from './insights';
import colleagueInsights from './colleagueInsights';
import companies from './companies';
import platforms from './platforms';
import billing from './billing';
import recentInteractions from './recentInteractions';
import members from './members';
import dashboard from './dashboard';
import colleaguesDashboard from './colleaguesDashboard';
import microsoft from './microsoft';
import messages from './messages';
import csv from './csv';
import settings from './settings';
import topics from './topics';
import subtopics from './subtopics';
import conversations from './conversations';
import annotations from './annotations';
import suggestedPainpoints from './suggestedPainpoints';
import teams from './teams';
import customFields from './customFields';
import labels from './labels';
import copilot from './copilot';
import exportData from './exportData';

const insightsEnpointPick = (isColleague) => (isColleague ? colleagueInsights : insights);

const insightsRelatedNamePick = (isColleague) => (isColleague ? 'colleague_id' : 'client_id');

export {
  axios,
  auth,
  mock,
  clients,
  messages,
  colleagues,
  members,
  insights,
  colleagueInsights,
  companies,
  platforms,
  billing,
  recentInteractions,
  dashboard,
  colleaguesDashboard,
  microsoft,
  settings,
  insightsEnpointPick,
  insightsRelatedNamePick,
  csv,
  topics,
  subtopics,
  conversations,
  annotations,
  suggestedPainpoints,
  teams,
  customFields,
  labels,
  copilot,
  exportData,
};
