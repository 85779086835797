import React, { useState } from 'react';
import { Dropdown, Menu, Modal } from 'antd';
import { EllipsisOutlined, EditOutlined, InboxOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router-dom';
import { members } from '@/api';
import GhostButton from '../GhostButton';
import { displayOrCatchMessage, loadListIfAvailable } from '../../helpers';

const onBeginArchive = (loadList, setRedirect, teamMember) => () => members.destroy(teamMember)
  .then(() => {
    loadList();
    setRedirect(true);
  }).catch((e) => {
    displayOrCatchMessage(e);
  });

const onArchive = (loadList, setRedirect, teamMember) => () => {
  Modal.confirm({
    title: `Are you sure you want to archive your team member ${teamMember.name}?`,
    content: 'Archiving your team members does not delete the data and insights that were gathered. '
      + 'You can re-activate your team members by adding them later.',
    okText: 'Archive',
    cancelText: 'Cancel',
    okType: 'primary',
    cancelType: 'default',
    onOk: onBeginArchive(loadList, setRedirect, teamMember),
    okButtonProps: {
      danger: true,
    },
  });
};

const menu = (loadList, setRedirect, teamMember, changeRole) => (
  <Menu>
    <Menu.Item>
      <GhostButton onClick={changeRole} type="primary" style={{ color: '#595959', fontWeight: 'normal' }}>
        <EditOutlined />
        Change Role
      </GhostButton>
    </Menu.Item>
    <Menu.Item>
      <GhostButton danger onClick={onArchive(loadList, setRedirect, teamMember)} type="primary" style={{ fontWeight: 'normal' }}>
        <InboxOutlined />
        Archive Team Member
      </GhostButton>
    </Menu.Item>
  </Menu>
);

const setShouldRedirect = (redirectsToTeamMembers, setValue) => (value) => {
  if (redirectsToTeamMembers) {
    setValue(value);
  }
};

const TeamMemberDropdown = ({
  loadList, teamMember, redirectsToTeamMembers, changeRole,
}) => {
  const [redirect, setRedirect] = useState(false);

  return (
    <>
      {
        redirect ? (
          <Redirect push to="/users" />
        ) : null
      }
      <Dropdown overlay={menu(loadListIfAvailable(loadList), setShouldRedirect(redirectsToTeamMembers, setRedirect), teamMember, changeRole)} trigger={['click']}>
        <GhostButton type="primary"><EllipsisOutlined style={{ fontSize: 24 }} /></GhostButton>
      </Dropdown>
    </>
  );
};

export default TeamMemberDropdown;
