import React, { useState, useMemo, useEffect } from 'react';
import {
  Modal as AntModal, Form, Button, message,
} from 'antd';
import moment from 'moment';
import { annotations } from '@/api';
import { useErrorState, displayOrCatch } from '../../../helpers';
import FormAnnotation from './FormAnnotation';

const AnnotationCreateModal = ({
  state, visible, onOk, onCancel,
}) => {
  const [form] = Form.useForm();

  const [error, setError] = useErrorState();

  const [loading, setLoading] = useState(false);

  const defaultValues = useMemo(() => ({
    label: '',
    date: '',
  }), []);

  useEffect(() => () => {
    setError(null);
  }, [visible]);

  const cancelHandler = () => {
    form.setFieldsValue(defaultValues);
    onCancel();
  };

  const formProps = {
    form,
    error,
    defaultValues,
    onSubmit: ({ label, date }) => {
      const modal = {
        label,
        date: moment(date).format('YYYY-MM-DD'),
        topic_id: state.id,
      };

      setLoading(true);

      annotations.createAnnotation(modal)
        .then(() => {
          form.setFieldsValue(defaultValues);
          setLoading(false);
          onOk();
          message.success('Release created successfully');
        })
        .catch((err) => {
          displayOrCatch(err, setError);
          setLoading(false);
        });
    },
  };

  return (
    <AntModal
      title={`Create release${state.name ? ` for ${state.name}` : ''}`}
      visible={visible}
      className="create-modal"
      width={650}
      onCancel={cancelHandler}
      destroyOnClose
      footer={[
        <Button
          key="cancel"
          disabled={loading}
          onClick={cancelHandler}
        >
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          size="large"
          loading={loading}
          onClick={() => form.submit()}
        >
          Create Release
        </Button>,
      ]}
    >
      <FormAnnotation {...formProps} />
    </AntModal>
  );
};

export default AnnotationCreateModal;
