import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Error from '@/containers/Error';
import ColleagueGeneralDashboard from '@/containers/ColleagueGeneralDashboard';
import Dashboard from '@/containers/Dashboard';
import Insights from '@/containers/Insights';
import InsightAnatomy from '@/containers/InsightAnatomy';
import ColleagueInsights from '@/containers/ColleagueInsights';
import Clients from '@/containers/Clients';
import Colleagues from '@/containers/Colleagues';
import TeamMembers from '@/containers/TeamMembers';
import Integrations from '@/containers/Integrations';
import Settings from '@/containers/Settings';
import AddFirstClient from '@/containers/Onboarding/AddFirstClient';
import AddFirstColleague from '@/containers/Onboarding/AddFirstColleague';
import AddFirstPlatform from '@/containers/Onboarding/AddFirstPlatform';
import AddFirstTopic from '@/containers/Onboarding/AddFirstTopic';
import GoToDashboard from '@/containers/Onboarding/GoToDashboard';
import Account from '@/containers/Account';
import Billing from '@/containers/Billing';
import PainpointsPage from '@/containers/Painpoints';
import TopicDashboard from '@/containers/TopicDashboard';
import People from '@/containers/People';
import PeopleDashboard from '@/containers/PeopleDashboard';
import Categories from '@/containers/Categories';
import TermsOfService from '@/containers/TermsOfService';
import PrivacyPolicy from '@/containers/PrivacyPolicy';
import ActivateEmail from '@/containers/ActivateEmail';

import UnauthenticatedRoute from '@/components/auth/UnauthenticatedRoute';
import OnboardedRoute from '@/components/onboarding/OnboardedRoute';
import ProtectedRoute from '@/components/auth/ProtectedRoute';
import ErrorBoundaryRoute from '@/components/ErrorBoundaryRoute';
import DemoRoute from '@/components/auth/DemoRoute';

import DashboardPage from '@/pages/DashboardPage';
import DiscoveryPage from '@/pages/DiscoveryPage';
import BucketPage from '@/pages/BucketPage';
import OnboardingPage from '@/pages/OnboardingPage';
import LoginPage from '@/pages/LoginPage';
import RegisterPage from '@/pages/RegisterPage';
import ForgotPasswordPage from '@/pages/ForgotPasswordPage';
import ResetPasswordPage from '@/pages/ResetPasswordPage';
import { TaxonomyPage } from '@/pages/TaxonomyPage';

const routes = {
  guest: [
    {
      path: '/login',
      component: LoginPage,
      exact: false,
    },
    {
      path: '/register',
      component: RegisterPage,
      exact: false,
    },
    {
      path: '/register/team',
      component: RegisterPage,
      exact: false,
    },
    {
      path: '/register/team-and-clients',
      component: RegisterPage,
      exact: false,
    },
    {
      path: '/forgot-password',
      component: ForgotPasswordPage,
      exact: false,
    },
    {
      path: '/password-reset/:token',
      component: ResetPasswordPage,
      exact: false,
    },
    {
      path: '/activate-email/:token',
      component: ActivateEmail,
      exact: false,
    },
    {
      path: '/invite/:token',
      component: RegisterPage,
      exact: false,
    },
    {
      path: '/terms',
      component: TermsOfService,
      exact: false,
    },
    {
      path: '/privacy',
      component: PrivacyPolicy,
      exact: false,
    },
  ],
  auth: [
    {
      path: '/account',
      component: Account,
      exact: false,
    },
    {
      path: '/initial-setup-ready',
      component: GoToDashboard,
      exact: false,
    },
    {
      path: '/add-clients',
      component: AddFirstClient,
      exact: false,
    },
    {
      path: '/add-team',
      component: AddFirstColleague,
      exact: false,
    },
    {
      path: '/onboarding',
      component: OnboardingPage,
      exact: false,
    },
    {
      path: '/onboarding/activate',
      component: OnboardingPage,
      exact: false,
    },
    {
      path: '/onboarding/processing',
      component: OnboardingPage,
      exact: false,
    },
    {
      path: '/add-topics',
      component: AddFirstTopic,
      exact: false,
    },
    {
      path: '/add-platforms',
      component: AddFirstPlatform,
      exact: false,
    },
    {
      path: '/add-topics',
      component: AddFirstTopic,
      exact: false,
    },
  ],
  demo: [
    {
      path: '/demo',
      component: DashboardPage,
      exact: true,
    },
    {
      path: '/demo/discovery',
      component: DiscoveryPage,
      exact: true,
    },
    {
      path: '/demo/topics',
      component: PainpointsPage,
      exact: true,
    },
    {
      path: '/demo/topic/:id/:groupId/:subtopicId',
      component: TopicDashboard,
      exact: false,
    },
    {
      path: '/demo/topic/:id/:groupId',
      component: TopicDashboard,
      exact: false,
    },
    {
      path: '/demo/people',
      component: People,
      exact: true,
    },
    {
      path: '/demo/people/:id',
      component: PeopleDashboard,
      exact: false,
    },
    {
      path: '/demo/categories',
      component: Categories,
      exact: false,
    },
  ],
  onboarded: [
    {
      path: '/',
      component: DashboardPage,
      exact: true,
    },
    {
      path: '/discovery',
      component: DiscoveryPage,
      exact: true,
    },
    {
      path: '/subscribed',
      component: Dashboard,
      exact: false,
    },
    {
      path: '/subscription-resumed',
      component: Dashboard,
      exact: true,
    },
    {
      path: '/subscription-cancelled',
      component: Dashboard,
      exact: true,
    },
    {
      path: '/start',
      component: ColleagueGeneralDashboard,
      exact: true,
    },
    {
      path: '/start/subscribed',
      component: ColleagueGeneralDashboard,
      exact: false,
    },
    {
      path: '/start/subscription-resumed',
      component: ColleagueGeneralDashboard,
      exact: false,
    },
    {
      path: '/start/subscription-cancelled',
      component: ColleagueGeneralDashboard,
      exact: false,
    },
    {
      path: '/insights',
      component: Insights,
      exact: true,
    },
    {
      path: '/insights/:id',
      component: InsightAnatomy,
      exact: false,
    },
    {
      path: '/colleague-insights',
      component: ColleagueInsights,
      exact: true,
    },
    {
      path: '/clients',
      component: Clients,
      exact: true,
    },
    {
      path: '/clients/:id',
      component: Clients,
      exact: false,
    },
    {
      path: '/clients/need_attention',
      component: Clients,
      exact: true,
    },
    {
      path: '/team-members',
      component: Colleagues,
      exact: true,
    },
    {
      path: '/team-members/:id',
      component: Colleagues,
      exact: false,
    },
    {
      path: '/team-members/need_attention',
      component: Colleagues,
      exact: true,
    },
    {
      path: '/users',
      component: TeamMembers,
      exact: true,
    },
    {
      path: '/integrations',
      component: Integrations,
      exact: false,
    },
    {
      path: '/billing',
      component: Billing,
      exact: false,
    },
    {
      path: '/topics',
      component: PainpointsPage,
      exact: true,
    },
    {
      path: '/topic/:currentTab/:topicId/:groupId?/:subtopicId?',
      component: BucketPage,
      exact: false,
    },
    {
      path: '/people',
      component: People,
      exact: true,
    },
    {
      path: '/people/:id',
      component: PeopleDashboard,
      exact: false,
    },
    {
      path: '/categories',
      component: Categories,
      exact: false,
    },
    {
      path: '/taxonomy',
      component: TaxonomyPage,
      exact: false,
    },
    {
      path: '/settings/:tab',
      component: Settings,
      exact: true,
    },
  ],
  redirects: [
    {
      path: '/painpoints',
      route: OnboardedRoute,
      exact: true,
      to: '/topics',
    },
    {
      path: '/demo/painpoints',
      route: DemoRoute,
      exact: true,
      to: '/demo/topics',
    },
    {
      path: '/settings',
      route: OnboardedRoute,
      exact: true,
      to: '/settings/exclude-conversations',
    },
  ],
};

export function Routes() {
  return (
    <Switch>
      {routes.onboarded.map((route) => (
        <OnboardedRoute key={route.path} {...route} />
      ))}
      {routes.demo.map((route) => (
        <DemoRoute key={route.path} {...route} />
      ))}
      {routes.auth.map((route) => (
        <ProtectedRoute key={route.path} {...route} />
      ))}
      {routes.redirects.map((route) => (
        <route.route key={route.path} exact={route.exact} path={route.path}>
          <Redirect to={route.to} />
        </route.route>
      ))}
      {routes.guest.map((route) => (
        <UnauthenticatedRoute key={route.path} {...route} />
      ))}

      {[403, 404, 500].map((code) => (
        <ErrorBoundaryRoute key={code} path={`/${code}`}>
          <Error code={code} />
        </ErrorBoundaryRoute>
      ))}
      <ErrorBoundaryRoute path="*">
        <Error code={404} />
      </ErrorBoundaryRoute>
    </Switch>
  );
}

export default routes;
