export function formatCount(count) {
  if (count >= 1000) {
    return `${(count / 1000).toFixed(1)}K`;
  }
  return count;
}

export function calculateDifference(value, previousValue) {
  const count = Math.abs(previousValue - value);
  const procent = (count / previousValue) * 100;
  if (procent > 100) return 100;
  if (Number.isNaN(procent)) return 0;
  return procent.toFixed(0);
}
