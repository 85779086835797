/* eslint-disable */
import React, { useState } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';

const SearchInput = ({ search, setSearch, ...rest }) => {
	const [isSearchExpanded, setSearchExpanded] = useState(false);

	const searchProps = {
		className: 'people__header__search',
		placeholder: 'Search People',
		prefix: <SearchOutlined onClick={() => setSearchExpanded(!isSearchExpanded)} />,
		onChange: debounce((e) => {
			setSearch(e.target.value);
		}, 400),
		defaultValue: search,
		style: !isSearchExpanded ? { width: '34px' } : { width: '200px' },
		...rest
	};

	return <Input {...searchProps} />;
};

export default SearchInput;
