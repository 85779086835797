import React from 'react';
import TopicMetric from '@/base-components/topic-metric';
import SentimentTooltip from '@/base-components/SentimentTooltip';
import Progress from '@/components/ui/progress';
import { Icons } from '@/components/ui/icons';
import { calculateDifference, formatCount } from '../../utils';
import FeedbackCount from '../FeedbackCount';
import { TaxonomyTableAction } from './TableAction';

export function getColumns(history, topic) {
  return [
    {
      title: () => (
        <div className="flex items-center gap-2">
          <Icons.Taxonomy className="w-4 h-4" />
          <span>Category</span>
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      onCell: (record) => ({
        onClick: () => {
          if (!record.has_children && 'groups' in record) {
            history.push(`${window.location.pathname.startsWith('/demo') ? '/demo/' : '/'}topic/overview/${record.id}/${record.groups[0].id}`);
          }

          if (!record.has_children && topic) {
            history.push(`${window.location.pathname.startsWith('/demo') ? '/demo/' : '/'}topic/overview/${topic.id}/${topic.groups[0].id}/${record.id}`);
          }
        },
      }),
    },
    {
      title: 'Feedback',
      dataIndex: 'count',
      key: 'count',
      width: '15%',
      render: (_, record) => {
        const { count = {} } = record;
        const difference = calculateDifference(
          count.value,
          count.previous_value,
        );
        const value = formatCount(count.value);
        return (
          <FeedbackCount
            value={value}
            trend={count.trend}
            percent={difference}
          />
        );
      },
    },
    {
      title: 'Ratio',
      dataIndex: 'ratio',
      key: 'ratio',
      width: '15%',
      render: (_, record = {}) => {
        const { ratio = {} } = record;
        return (
          <TopicMetric
            value={`${Math.ceil(ratio.value * 100)}%`}
            trend={ratio.trend}
            tooltip="The ratio of feedback compared to the total in the selected period"
          />
        );
      },
    },
    {
      title: 'MRR',
      dataIndex: 'mrr',
      key: 'mrr',
      width: '15%',
      render: (_, record) => {
        const { mrr = {} } = record;
        return (
          <TopicMetric.MRR
            value={mrr.value}
            trend={mrr.trend}
            tooltip="Monthly recurring revenue from users talking about this painpoint"
          />
        );
      },
    },
    {
      title: 'Sentiment',
      dataIndex: 'sentiment',
      key: 'sentiment',
      width: '15%',
      render: (_, record) => {
        const { sentiment = {} } = record;
        return (
          <SentimentTooltip sentiment={sentiment} style={{ display: 'block' }}>
            <Progress.Sentiment
              positive={sentiment.positive}
              negative={sentiment.negative}
            />
          </SentimentTooltip>
        );
      },
    },
    {
      title: '',
      key: 'action',
      // eslint-disable-next-line no-unused-vars
      render: (_, record) => <TaxonomyTableAction record={record} topic={topic} />,
    },
  ];
}
