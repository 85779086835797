/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import {
  CloseOutlined,
  DeleteOutlined, EditOutlined, PlusOutlined, TagOutlined,
} from '@ant-design/icons';
import {
  Search, Tags,
} from 'lucide-react';
import {
  Button, Tooltip, Checkbox, Dropdown, Empty, Input, Menu, Spin, Tag, message,
} from 'antd';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { conversations, labels } from '@/api';
import { displayOrCatchMessage } from '@/helpers';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import useLabels from '../discovery/hooks/useLabels';
import { useConversationStore } from '../../store/zustand/useConversationsStore';

export function useLabelsOld() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const getLabels = async () => {
      try {
        const { data: res } = await labels.getAll();

        setData(res.data);
        setLoading(false);
      } catch (e) {
        displayOrCatchMessage(e);
      }
    };

    getLabels();
  }, [reload]);

  return {
    loading, data, reload, setReload,
  };
}

export default function LabelsTab() {
  return (
    <div className="settings-tab container-sm">
      <h2>Create or edit labels</h2>
      <p>Use labels to help organize and filter conversations in your workspace.</p>
      <ActiveLabelList />
    </div>
  );
}

const ActiveLabelList = () => {
  const [visible, setVisible] = useState(false);
  const { loading, data, setReload } = useLabelsOld();

  const handleSave = async (id, payload) => {
    try {
      await labels.createLabel(payload);
      setReload((prev) => !prev);

      message.success('The label has been created successfully', 1.5);
      setVisible(false);
    } catch (e) {
      displayOrCatchMessage(e);
    }
  };

  if (loading) {
    return (
      <div className="mt-8 flex max-w-xl justify-center">
        <Spin />
      </div>
    );
  }

  return (
    <div className="mt-8 max-w-xl">
      <div className="flex items-baseline justify-between">
        <h4 className="text-[#595959]">Active labels</h4>
        <Button onClick={() => setVisible(true)} type="primary">
          New Label
        </Button>
      </div>
      {visible ? (
        <li className="p-2.5 bg-[#F6F9FB] mt-3.5 border rounded-md border-solid border-[#E8E8E8] text-[#595959] last:border-none flex justify-between">
          <EditLabelItem onCancel={() => setVisible(false)} onSave={handleSave} />
        </li>
      ) : null}

      {data.length > 0 ? (
        <ul className="mt-2 list-none p-0 border rounded-md border-solid border-[#E8E8E8]">
          {data.map((label) => (
            <LabelItem key={label.name} item={label} setReload={setReload} />
          ))}
        </ul>
      ) : (
        <div className="mt-2 p-2 rounded-md border border-solid border-[#E8E8E8]">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No labels had been created" />
        </div>
      )}
    </div>
  );
};

const LabelItem = ({ item, setReload }) => {
  const [edit, setEdit] = useState(false);

  const editLabel = () => {
    setEdit(true);
  };

  const deleteLabel = async (id) => {
    try {
      await labels.deleteLabel(id);
      setReload((prev) => !prev);
      message.success('The label has been deleted successfully', 1.5);
    } catch (e) {
      displayOrCatchMessage(e);
    }
  };

  const handleSave = async (id, payload) => {
    try {
      await labels.updateLabel(id, payload);
      setReload((prev) => !prev);

      message.success('The label has been updated successfully', 1.5);
      setEdit(false);
    } catch (e) {
      displayOrCatchMessage(e);
    }
  };

  return (
    <li className={classNames('group text-[#595959] border-0 border-b border-[#E8E8E8] border-solid last:border-none flex justify-between', !edit ? 'px-5 py-2' : 'p-2.5 bg-[#F6F9FB]')}>
      {edit ? (
        <EditLabelItem id={item.id} name={item.name} color={item.color} onCancel={() => setEdit(false)} onSave={handleSave} />
      ) : (
        <>
          <div className="flex items-center space-x-3">
            <div className="rounded-full w-2 h-2 " style={{ backgroundColor: item.color }} />
            <span>{item.name}</span>
            <span className="text-[#595959] text-xs font-[Gordita-Regular]">
              ·
              {' '}
              {item.conversations_count}
              {' '}
              conversations
            </span>
          </div>
          <div className="opacity-0 transition-opacity group-hover:opacity-100">
            <Button onClick={editLabel} type="text">
              <EditOutlined />
            </Button>
            <Button onClick={() => deleteLabel(item.id)} type="text">
              <DeleteOutlined />
            </Button>
          </div>

        </>
      )}

    </li>
  );
};

const defaultColors = [
  '#C1CBD4',
  '#778A9F',
  '#3679BA',
  '#68CEFB',
  '#5BCEB6',
  '#EBBD77',
  '#E38445',
  '#EA4682',
  '#AF7BEB',
  '#EBB8B8',
  '#C4E181',
  '#C3DEDC',
];

const createColorsMenu = ({ defaultColor, setActiveColor }) => (
  <Menu defaultSelectedKeys={[defaultColor]} selectable className="grid grid-cols-4 grid-rows-3 gap-1" onSelect={(color) => setActiveColor(color.key)}>
    {defaultColors.map((color) => (
      <Menu.Item key={color} className={classNames('rounded-md', color === defaultColor ? '!bg-gray-100' : null)}>
        <div className="rounded-full w-6 h-6 " style={{ backgroundColor: color }} />
      </Menu.Item>
    ))}
  </Menu>
);

const EditLabelItem = ({
  id, name, color, onCancel, onSave,
}) => {
  const [value, setValue] = useState(name);
  const [activeColor, setActiveColor] = useState(color || '#C1CBD4');
  return (
    (
      <div className="flex justify-between min-w-full">
        <div className="flex space-x-2">
          <Dropdown overlay={createColorsMenu({ defaultColor: activeColor, setActiveColor })}>
            <Button style={{ padding: '0 11px' }}>
              <span className="rounded-full w-2 h-2 " style={{ backgroundColor: activeColor }} />
            </Button>
          </Dropdown>
          <Input
            onChange={debounce((e) => {
              setValue(e.target.value);
            }, 150)}
            defaultValue={value}
            placeholder="Label name"
          />
        </div>
        <div className="space-x-2">
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={() => onSave(id, { name: value, color: activeColor })} type="primary">Save</Button>
        </div>
      </div>
    )
  );
};

export const LabelsButton = ({
  conversationId,
  activeLabels,
}) => {
  const queryClient = useQueryClient();
  const { getConversation, setConversation } = useConversationStore((state) => state);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const {
    data, isLoading, error, isError,
  } = useLabels();
  const [list, setList] = useState(data ?? []);

  useEffect(() => {
    if (!data) return;
    setList(data);
  }, [data]);

  const labelCreate = useMutation({
    mutationKey: ['labels_create', search],
    mutationFn: async (label) => {
      const { data: response } = await labels.createLabel({ name: label.search, color: label.defaultColor });
      return response;
    },
    onMutate: async (label) => {
      await queryClient.cancelQueries({ queryKey: ['labels'] });
      const previousLabels = queryClient.getQueryData(['labels']);

      const optimisticLabel = { id: 'temp_id', name: label.search, color: label.defaultColor };
      if (previousLabels) {
        queryClient.setQueryData(['labels'], (old) => ([...old, optimisticLabel]));
      }

      return { previousLabels };
    },
    onSuccess: async (response, variables) => {
      message.success('The label has been created successfully', 1.5);
      await conversations.assignLabel(variables.conversationId, response.data.id, 'assign');
      const { labels } = getConversation(variables.conversationId);
      labels.push(response.data);
      setConversation(variables.conversationId, labels);
      setSearch('');
    },
    onError: (err, variables, ctx) => {
      if (ctx?.previousConvs) {
        queryClient.setQueryData(['labels'], ctx.previousConvs);
      }
      displayOrCatchMessage(err);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['labels']);
    },
  });

  const labelChange = useMutation({
    mutationKey: ['labels_change', conversationId],
    mutationFn: async (props) => {
      await conversations.assignLabel(conversationId, props.label.id, props.action);
    },
    onSuccess: (data, variables, ctx) => {
      const { labels } = getConversation(variables.conversationId);
      if (variables.action === 'assign') {
        labels.push(variables.label);
      } else {
        labels.splice(labels.findIndex((item) => item.id === variables.label.id), 1);
      }
      setConversation(variables.conversationId, labels);
      message.success('The label has been updated successfully', 1.5);
    },
    onError: (e) => displayOrCatchMessage(e),
  });

  const handleLabelCreate = () => {
    const defaultColor = defaultColors[Math.floor(Math.random() * defaultColors.length)];
    labelCreate.mutate({ conversationId, search, defaultColor });
  };

  const handleLabelChange = (event, label) => {
    const { checked } = event.target;
    const action = checked ? 'assign' : 'remove';

    labelChange.mutate({ conversationId, action, label });
  };

  return (
    <Dropdown
      className="cursor-pointer"
      visible={open}
      onVisibleChange={(visible) => setOpen(!!visible)}
      trigger={['click']}
      overlay={(
        <Menu className="!rounded-md shadow-[0px_1px_12px_#CAD7E180] border border-solid border-[#DFE9F1]">
          <Input
            className="!w-[calc(100%-15px)] mx-2 my-2.5"
            placeholder="Find or Create Label"
            prefix={<Search className="pr-0.5" size={16} strokeWidth={2} color="#75899B" />}
            onChange={debounce((e) => {
              setSearch(e.target.value);
              setList(list.filter((label) => label.name.toUpperCase().includes(e.target.value.toUpperCase())));
            }, 300)}
          />

          {list.length > 0 ? list.map((label) => (
            <Menu.Item key={label.name} className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]">
              <Checkbox checked={activeLabels.find((item) => item.id === label.id)} onChange={(e) => handleLabelChange(e, label)} className="flex items-center gap-2 [&>span]:flex [&>span]:items-center [&>span]:gap-2">
                <div className="rounded-full w-2 h-2 " style={{ backgroundColor: label.color }} />
                <span className="ml-0.5">{label.name}</span>
              </Checkbox>
            </Menu.Item>
          )) : (
            <>
              <Menu.Item className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]" key="no-label" onClick={handleLabelCreate}>
                <PlusOutlined className="mr-2" />
                {' '}
                Create Label
              </Menu.Item>
              <div className="flex justify-center items-center h-10">
                <span className="text-gray-500">No labels found</span>
              </div>
            </>
          )}
        </Menu>
      )}
      placement="bottomRight"
    >
      <Tooltip title="Add label">
        <Tags className="hover:stroke-[#44596C]" color="#8F9DA9" size={16} />
      </Tooltip>
    </Dropdown>
  );
};

export const LabelTag = ({ label }) => (

  <Tag
    className="flex items-center gap-1.5 !border-dashed !border-[#C6C6C6] !rounded-full !pl-2 !pr-3 !py-1 !bg-white !ml-0.5"
  >
    <div className="rounded-full w-2 h-2 " style={{ backgroundColor: label.color }} />
    <span className="text-[#595959]">{label.name}</span>
  </Tag>

);

export const SelectedLabel = ({ label, onClear }) => (
  <div className="selected-custom-field">
    <TagOutlined />
    <span>
      Label:
    </span>
    <div className="flex items-center gap-2">
      <div className="rounded-full w-2 h-2 " style={{ backgroundColor: label.color }} />
      <span className="text-[#262626]">{label.name}</span>
    </div>
    <Button onClick={() => onClear(label.id)} type="link"><CloseOutlined /></Button>
  </div>
);
