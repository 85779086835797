import { topics } from '@/api';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {object} TopicGroup
 * @property {number} id
 * @property {string} name
 * @property {boolean} has_children
 * @property {string} color
 * @property {object} count
 * @property {number} count.value
 * @property {number} count.previous_value
 * @property {string} count.trend
 * @property {object} sentiment
 * @property {number} sentiment.positive
 * @property {number} sentiment.negative
 * @property {object} mrr
 * @property {number} mrr.value
 * @property {number} mrr.previous_value
 * @property {string} mrr.trend
 * @property {object} ratio
 * @property {number} ratio.value
 * @property {number} ratio.previous_value
 * @property {string} ratio.trend
 */

/**
 * ReactQuery wrapper to fetch groups of topics
 * @param {?object} filters - filters to pass to the API
 * @param {import('@tanstack/react-query').UseQueryOptions} options - extra options from react-query
 * @returns {import('@tanstack/react-query').UseQueryResult<TopicGroup[], unknown>}
 */
export default function useTaxonomy(filters, options) {
  return useQuery({
    queryKey: ['taxonomy', filters],
    queryFn: async () => {
      const { data } = await topics.getTopicGroups(filters);
      return data;
    },
    ...options,
  });
}
