import React from 'react';
import { Modal, Button } from 'antd';
import AnnotationsTable from '@/base-components/annotations-table';

const AnnotationListModal = ({
  state, visible, onOk, onCancel,
}) => {
  const annotationsTableProps = {
    onDelete: onOk,
    filters: { topic_id: state.id },
    perPage: 5,
  };

  return (
    <Modal
      title={`Releases${state.name ? ` for ${state.name}` : ''}`}
      visible={visible}
      className="list-modal"
      width={650}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <Button
          key="done"
          type="primary"
          size="large"
          onClick={onCancel}
        >
          Done
        </Button>,
      ]}
    >
      <AnnotationsTable {...annotationsTableProps} />
    </Modal>
  );
};

export default AnnotationListModal;
