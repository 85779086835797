import moment from 'moment';

export const defaultDates = [
  {
    name: '7D',
    start: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: 'last 7 days',
  },
  {
    name: '30D',
    start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: 'last 30 days',
  },
  {
    name: '3M',
    start: moment().subtract(3, 'month').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: 'last 3 months',
  },
  {
    name: '6M',
    start: moment().subtract(6, 'month').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: 'last 6 months',
  },
];
