import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import './SummaryResults.less';
import SummaryResultItem from '@/components/summary/SummaryResultItem';
import {
  Empty, Spin, message, Button,
} from 'antd';
import { cn } from '@/helpers/util';
import { useFiltersDispatch } from '@/components/filter/context';

export const getSummaryText = (item) => {
  const principal = 'users';
  let base;

  if (item.summary.indexOf('users') !== -1) {
    base = `${item.summary}`;
  } else {
    switch (item.intent) {
      case 'issue': base = item.summary.indexOf('facing') !== -1 ? item.summary : `${principal} are facing ${item.summary}`;
        break;
      case 'question': base = item.summary.indexOf('ask') !== -1 ? item.summary : `${principal} are asking about the ${item.summary}`;
        break;
      case 'request': base = item.summary.indexOf('request') !== -1 ? item.summary : `${principal} are requesting information about the ${item.summary}`;
        break;
      case 'other':
      default: base = `${item.summary}`;
    }
  }

  return `of relevant feedback suggests: ${base}`;
};

export default function SummaryResults({
  className, isLoading, error, isError, data, total, perPage = 6, handleSeeMore, seeMoreText, disableClick,
}) {
  const dispatch = useFiltersDispatch();
  const sortedData = useMemo(() => data.sort((a, b) => b.count - a.count), [data]);
  const [currentPage, setCurrentPage] = useState(1);
  const visibleData = useMemo(() => sortedData.slice(0, currentPage * perPage), [sortedData, currentPage, perPage]);
  const showNextPage = useCallback(() => setCurrentPage((current) => current + 1), []);

  const [selectedId, setSelectedId] = useState(null);
  useEffect(() => {
    dispatch({ type: 'SET_FILTERS_FIELD', field: 'topic_summary_result_id', value: selectedId });
  }, [selectedId]);

  useEffect(() => {
    if (isError) {
      message.error(error.message, 3);
    }
  }, [error]);

  useEffect(() => {
    setCurrentPage(1);
  }, [sortedData]);

  if (isLoading) {
    return (
      <div className={cn('summary-results', className)}>
        <div className="text-center m-8">
          <Spin />
        </div>
      </div>
    );
  }

  return (
    <div className={cn('summary-results overflow-y-scroll', className)}>
      {!visibleData.length ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results" />
      ) : visibleData.map((item) => (
        <SummaryResultItem
          key={item.id}
          id={item.id}
          type={item.intent}
          text={getSummaryText(item)}
          mentions={item.count}
          total={total}
          handleSelect={setSelectedId}
          disableClick={disableClick}
        />
      ))}
      {
        visibleData.length !== sortedData.length ? (
          <div className="flex m-2 justify-center">
            <Button onClick={handleSeeMore || showNextPage} size="small" type="secondary">{seeMoreText || 'See more'}</Button>
          </div>
        ) : null
      }
    </div>
  );
}
