import React, { useState } from 'react';
import { Typography } from 'antd';
import TeamMemberDropdown from './TeamMemberDropdown';
import LoadingTable from '../LoadingTable';
import Avatar from '../clients/Avatar';
import GhostButton from '../GhostButton';
import TeamMemberRoleModal from './TeamMemberRoleModal';

const { Text } = Typography;

const setModalRolesVisibleAtIndex = (index, modalRolesVisible, setModalRolesVisible) => (value) => {
  const copy = [...modalRolesVisible];
  copy[index] = value;

  setModalRolesVisible(copy);
};

const columns = (loadList, modalRolesVisible, setModalRolesVisible) => [
  {
    title: 'Avatar',
    dataIndex: 'avatar',
    key: 'avatar',
    width: 46,
    render: (image, client) => <Avatar size={46} client={client} />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <h4 className="table-text-bold">{text}</h4>,
  },
  {
    title: 'Role',
    key: 'role',
    // eslint-disable-next-line no-nested-ternary
    render: (teamMember, data, index) => (data.userId ? (((data.role || data.owner) ? (
      <Text type="secondary">{data.role ? data.role : 'Managing Director'}</Text>
    ) : (
      <GhostButton type="primary" onClick={setModalRolesVisibleAtIndex(index, modalRolesVisible, setModalRolesVisible)}>Add Role</GhostButton>
    ))) : (
      <Text type="secondary">Invite pending</Text>
    )),
    className: 'hide-tablet-v',
  },
  {
    title: 'Actions',
    width: 100,
    render: (teamMember, data, index) => (data.userId ? (
      <>
        <TeamMemberRoleModal
          loadList={loadList}
          member={teamMember}
          visible={modalRolesVisible[index]}
          setVisible={setModalRolesVisibleAtIndex(index, modalRolesVisible, setModalRolesVisible)}
        />
        <TeamMemberDropdown
          loadList={loadList}
          teamMember={teamMember}
          changeRole={setModalRolesVisibleAtIndex(index, modalRolesVisible, setModalRolesVisible)}
        />
      </>
    ) : null),
  },
];

const TeamMemberTable = ({
  teamMembers, loadList, ...props
}) => {
  const [modalRolesVisible, setModalRolesVisible] = useState(teamMembers.map(() => false));

  return (
    <LoadingTable
      showHeader={false}
      columns={columns(loadList, modalRolesVisible, setModalRolesVisible)}
      dataSource={teamMembers}
      {...props}
    />
  );
};

export default TeamMemberTable;
