import React, { useContext } from 'react';
import {
  ArrowLeftRight, BadgeCheck, Check, Clock,
} from 'lucide-react';
import { Button } from 'antd';
import { platforms } from '@/api';
import { useMutation } from '@tanstack/react-query';
import { displayOrCatchMessage } from '@/helpers';
import { OnboardingContext, PlatformContext } from '../context';
import { getPlatformImage } from '../utils/platforms';
import { Logo } from '../layout';
import { usePlatforms } from '../hooks/usePlatforms';

export function ConnectedPlatform() {
  const { platform } = useContext(PlatformContext);
  const { setStep } = useContext(OnboardingContext);
  const { refetch } = usePlatforms();

  const activatePlatform = useMutation({
    mutationKey: 'activate_platform',
    mutationFn: async (type) => {
      let activate = null;
      if (type === 'csv') return;
      switch (type) {
        case 'intercom':
          activate = platforms.activateIntercom;
          break;
        case 'zendesk':
          activate = platforms.activateZendesk;
          break;
        default:
          activate = platforms.activateZendesk;
      }

      await activate();
    },
    onSuccess: async () => {
      await refetch();
      setStep(3);
    },
    onError: (e) => {
      displayOrCatchMessage(e);
    },
  });

  const onClick = () => {
    const type = platform.toLowerCase();
    activatePlatform.mutate(type);
  };

  return (
    <section className="flex flex-col items-center font-[Gordita-Regular]">
      <div className="flex items-center justify-center gap-3">
        <div className="relative flex items-center justify-center w-12 h-12 rounded-md bg-[#EFF4F8]">
          <span className="absolute bg-[#0CAA6B] flex rounded-full text-white p-1 -top-1.5 -right-1.5">
            <Check className="h-2.5 w-2.5" strokeWidth={3} />
          </span>
          {getPlatformImage(platform.toLowerCase())}
        </div>
        <ArrowLeftRight color="#0CAA6B" />
        <div className="flex items-center justify-center w-12 h-12 rounded-md bg-[#EFF4F8]">
          <Logo />
        </div>
      </div>
      <h1 className="m-0 mt-2.5 text-[#44596C] font-[Gordita-Medium] text-center text-base md:text-lg md:mt-4">
        Connection succesful!
      </h1>
      <p className="m-0 mt-2 max-w-sm text-center text-[#75899B]">
        Now that your source is connected, we can start pulling data with your consent.
      </p>
      <ul className="m-0 p-0 list-none mt-12 font-[Gordita-Medium] text-[#44596C] [&_*]:flex [&_*]:items-center [&_*]:gap-3.5 [&_*]:text-sm space-y-2.5">
        <li>
          <BadgeCheck color="#0CAA6B" className="h-4 w-4" />
          10k messages/tickets analysis included in your FREE trial
        </li>
        <li>
          <BadgeCheck color="#0CAA6B" className="h-4 w-4" />
          Worried about your data? Cancel and delete anytime.
        </li>
      </ul>
      <div className="flex gap-2 items-center mt-12 text-[#75899B]">
        <Clock className="h-4 w-4" />
        <span>Estimated time: 3-8min</span>
      </div>
      <div className="max-w-sm w-full">
        <Button
          loading={activatePlatform.isLoading}
          onClick={onClick}
          size="large"
          type="primary"
          className="mt-3.5 w-full"
        >
          Start insights discovery
        </Button>
      </div>
    </section>
  );
}
