import React, {
  useReducer,
  useEffect,
} from 'react';
import { Table, message } from 'antd';
import { annotations } from '@/api';
import {
  initialState,
  annotationsTableReducer,
  SET_DATA_ACTION,
  SET_LOADING_ACTION,
  SET_RELOAD_ACTION,
} from '../annotationsTableReducer';
import { getColumns } from '../columns';

const AnnotationsTable = ({ filters, perPage, onDelete }) => {
  const [{
    data, loading, pagination, reload,
  },
  dispatch] = useReducer(annotationsTableReducer, initialState);

  const getAnnotations = async (page) => {
    dispatch({ type: SET_LOADING_ACTION, payload: true });

    annotations.getAnnotations(filters, perPage, page)
      .then((response) => {
        dispatch({ type: SET_DATA_ACTION, payload: response });
      })
      .catch(() => {
        dispatch({ type: SET_LOADING_ACTION, payload: false });

        message.error('Release Error');
      });
  };

  const handleDelete = (id) => {
    annotations.deleteAnnotation(id)
      .then(() => {
        dispatch({ type: SET_RELOAD_ACTION });
        onDelete?.();
        message.success('Release deleted successfully');
      })
      .catch(() => {
        message.error('Delete Release Error');
      });
  };

  useEffect(() => {
    getAnnotations(pagination.current);
  }, [filters, perPage, reload]);

  return (
    <Table
      rowKey={({ id }) => id}
      className="annotations-table"
      scroll={{ x: 'max-content' }}
      columns={getColumns(handleDelete)}
      loading={loading}
      dataSource={data}
      pagination={{
        ...pagination,
        onChange: getAnnotations,
      }}
    />
  );
};

export default AnnotationsTable;
