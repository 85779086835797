import { buildQuery } from '@/helpers/filters';
import axios from './axios';
import auth from './auth';

const index = (filters, page) => axios.get(buildQuery('/api/members', filters, page ? {
  team_id: auth.team().id,
  page,
} : { team_id: auth.team().id })).then((response) => response).catch((e) => Promise.reject(e));

const store = (values) => axios.post('/api/members', {
  ...values,
  team_id: auth.team().id,
}).then((response) => response).catch((e) => Promise.reject(e));

const storeBatch = (values) => axios.post('/api/members-batch', {
  ...values,
  team_id: auth.team().id,
}).then((response) => response).catch((e) => Promise.reject(e));

const update = (member) => (values) => axios.put(`/api/members/${member.userId}`, {
  ...values,
  team_id: auth.team().id,
}).then((response) => response).catch((e) => Promise.reject(e));

const destroy = (member) => axios.delete(`/api/members/${member.key}`, {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response).catch((e) => Promise.reject(e));

const members = {
  index,
  store,
  storeBatch,
  destroy,
  update,
};

export default members;
