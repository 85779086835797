import React from 'react';
import Card from '@/components/card';
import { cn } from '@/helpers/util';
import { Button, List } from 'antd';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { useDashboard } from '@/data/useDashboard';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';
import TrendingLine from './TrendingLine';

export function TrendingCard({
  className, topics, total,
}) {
  const { defaultDate } = useDefaultDateStore();
  const isWeek = moment(defaultDate.end).diff(moment(defaultDate.start), 'months') >= 3 ? 1 : 0;
  const {
    isLoading, isFetching,
  } = useDashboard({
    start: defaultDate.start,
    end: defaultDate.end,
    week: isWeek,
  });

  const graphTotal = topics.length > 0 ? (
    parseInt(topics[0].negative_count, 10)
    + parseInt(topics[0].positive_count, 10)
    + parseInt(topics[0].neutral_count, 10)
  ) : 0;

  return (
    <Card className={cn('pt-[16px] pb-8 mb-4', className)}>
      <Card.Header>
        <div className="flex items-center divide-y-0 divide-x-2 flex-1 gap-7 divide-solid divide-[#DFE9F1]">
          <Card.Header.Title title="Trending" />
        </div>
        <Link to="/categories">
          <Button className="ant-btn ant-btn-secondary mt-[-2px]">
            View all
          </Button>
        </Link>
      </Card.Header>
      <Card.Body>
        <List
          loading={isLoading || isFetching}
          className="[&_.ant-list-header]:p-0 [&_.ant-list-header]:!border-0 [&_.ant-list-items]:space-y-2"
          dataSource={topics}
          renderItem={(item) => (
            <TrendingLine
              topic={item}
              total={total}
              graphTotal={graphTotal}
              filters={{
                start: defaultDate.start,
                end: defaultDate.end,
              }}
            />
          )}
        />
      </Card.Body>
    </Card>
  );
}
