import { dashboard } from '@/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useDashboard({
  start, end, search = undefined, week,
}) {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['dashboard', {
      start, end, search, week,
    }],
    queryFn: async () => {
      const { data: response } = await dashboard.allNew(start, end, search, week);
      if (search) {
        queryClient.setQueryData(['dashboard', {
          start, end, week,
        }], response.data);
      }
      return response.data;
    },
  });
}
