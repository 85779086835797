import { labels } from '@/api';
import { useQuery } from '@tanstack/react-query';

export default function useLabels() {
  return useQuery({
    queryKey: ['labels'],
    queryFn: async () => {
      const { data: res } = await labels.getAll();
      return res.data;
    },
    refetchOnWindowFocus: false,
  });
}
