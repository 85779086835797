import auth from './auth';
import axios from './axios';

const index = () => axios.get('/api/platforms').then((response) => response).catch((e) => Promise.reject(e));

const getTeamPlatforms = () => axios.get('/api/platforms/team').then((response) => response).catch((e) => Promise.reject(e));

const connectGoogle = (values) => axios.post('/api/platforms/google', values).then((response) => response).catch((e) => Promise.reject(e));

const connectIntercom = (values) => axios.post('/api/platforms/intercom', values).then((response) => response).catch((e) => Promise.reject(e));

const connectTypeform = (values) => axios.post('/api/platforms/typeform', values).then((response) => response).catch((e) => Promise.reject(e));

const connectZendeskSupport = (values) => axios.post('/api/platforms/zendesk', values).then((response) => response).catch((e) => Promise.reject(e));

const disconnectIntercom = (values) => axios.post('/api/platforms/intercom/disconnect', values).then((response) => response).catch((e) => Promise.reject(e));

const disconnectTypeform = (values) => axios.post('/api/platforms/typeform/disconnect', values).then((response) => response).catch((e) => Promise.reject(e));

const disconnectZendeskSupport = (values) => axios.post('/api/platforms/zendesk/disconnect', values).then((response) => response).catch((e) => Promise.reject(e));

const connectParagonIntercom = (values) => axios.post('/api/platforms/paragon/intercom', values).then((response) => response).catch((e) => Promise.reject(e));

const getZapierApiKey = () => axios.get('/api/zapier/key',
  {
    params: {
      team_id: auth.team().id,
    },
  })
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const getTypeformForms = () => axios.get('/api/typeform/forms')
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const getTypeformAvailableForms = (search) => axios.get('/api/typeform/availableForms',
  {
    params: {
      search,
    },
  })
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const updateTypeformName = (id, values) => axios.put(`/api/typeform/form/${id}`, values, {
  params: {
    team_id: auth.team().id,
  },
});

const setTypeformForms = (ids, names) => axios.post('/api/typeform/forms',
  {
    ids,
    names,
  })
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const activateIntercom = () => axios.post('/api/intercom/activate')
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const activateZendesk = () => axios.post('/api/zendesk/activate')
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const clients = {
  index,
  getTeamPlatforms,
  connectGoogle,
  connectIntercom,
  connectTypeform,
  connectZendeskSupport,
  disconnectIntercom,
  disconnectTypeform,
  disconnectZendeskSupport,
  connectParagonIntercom,
  getZapierApiKey,
  getTypeformForms,
  updateTypeformName,
  getTypeformAvailableForms,
  setTypeformForms,
  activateZendesk,
  activateIntercom,
};

export default clients;
