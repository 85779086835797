import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import SegmentLine from '../ui/segment/SegmentLine';

export default function TrendingLine({
  topic, total, graphTotal,
}) {
  const value = useMemo(() => {
    if (!topic) return 0;

    const { count } = topic;
    if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}K`;
    }
    return count;
  }, [topic]);

  const history = useHistory();
  const width = Math.ceil(total > 0 ? (topic.count / graphTotal) * 100 : 0);
  const percent = useMemo(() => {
    if (!topic) return 0;

    const count = Math.abs(topic.previous_count - topic.count);
    const procent = (count / topic.previous_count) * 100;
    if (procent > 100) return 100;
    if (Number.isNaN(procent)) return 0;
    return procent.toFixed(0);
  }, [topic]);

  const handleClick = () => {
    history.push(`/topic/overview/${topic.id}/${topic.group_id}`);
  };

  return (
    <SegmentLine
      name={topic.name}
      width={width}
      percent={percent}
      value={value}
      trend={topic.trend}
      onClick={handleClick}
    />
  );
}
