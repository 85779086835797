import React from 'react';
import { Divider, List, Tooltip } from 'antd';
import { cn } from '@/helpers/util';
import { ArrowDown, ArrowUp } from 'lucide-react';
import './SegmentLine.less';

/**
 * SegmentLine component.
 *
 * @component
 * @param {Object} props - The component accepts text and onClick as props
 * @param {Object} props.selected - It's a boolean value to check if the segment is selected.
 * @param {string} props.name - The text to display on the segment.
 * @param {number} props.width - The width of the segment.
 * @param {number} props.value - The value of the segment.
 * @param {string} props.trend - The trending direction of the segment.
 * @param {number} props.percent - The percentage change of the segment.
 * @param {string} props.className - Additional classes to apply to the segment line root.
 * @param {function} props.onClick - The function to call when the segment is clicked.
 * @returns {JSX.Element} The rendered segment line component.
 *
 * @example
 * // Render a SegmentLine with the title "Segment 1"
 * <SegmentLine selected={false} name="Segment 1" width={50} value={100} trend="ascending" percent={10} onClick={() => console.log('Segment clicked!')} />
 */
export default function SegmentLine({
  selected,
  name,
  width,
  value,
  trend,
  percent,
  onClick,
  className,
}) {
  return (
    <List.Item
      onClick={onClick}
      className={cn(
        'segment-line cursor-pointer group flex justify-between items-center gap-x-8',
        className,
      )}
    >
      <div className="w-full relative grid items-center">
        <span
          className={cn(
            'font-[Gordita-Medium] text-[#202324] z-10 px-2.5 py-[5px] group-hover:underline whitespace-nowrap overflow-hidden text-ellipsis',
            selected && 'underline',
          )}
        >
          {name}
        </span>
        <div
          className={cn(
            'absolute h-full bg-[#EBF4FF] group-hover:bg-[#E4F0FE] rounded-md max-w-full',
            selected && 'bg-[#E4F0FE]',
          )}
          style={{
            width: `${width}%`,
          }}
        />
      </div>
      <div className="flex items-center gap-x-0.5">
        <Tooltip title="The ammount of feedback in the current period">
          <span className="block text-[#202324] font-[Gordita-Medium]">
            {value}
          </span>
        </Tooltip>
        <Divider
          type="vertical"
          className="[&.ant-divider-vertical]:border-[#CEDBE4] [&.ant-divider-vertical]:h-5"
        />
        <Tooltip title="Change in feedback compared to the previous period">
          <div className="flex items-center gap-x-0.5">
            {trend === 'ascending' ? (
              <ArrowUp className="ml-[-2px]" size={16} strokeWidth={2.5} color="#F17E99" />
            ) : (
              <ArrowDown className="ml-[-2px]" size={16} strokeWidth={2.5} color="#A8BCCF" />
            )}
            <span className="text-[#75899B] text-[12px] leading-6 w-11">
              {trend === 'ascending' ? '+' : '-'}
              {percent}
              %
            </span>
          </div>
        </Tooltip>
      </div>
    </List.Item>
  );
}
