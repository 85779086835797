import React from 'react';
import {
  Card, Tooltip,
} from 'antd';
import { INTEGRATION_TYPE } from '../constants';
import Intercom from '../svgs/Intercom';
import Csv from '../svgs/Csv';
import Zendesk from '../svgs/Zendesk';
import ZendeskInactive from '../svgs/ZendeskInactive';
import Hubspot from '../svgs/Hubspot';
import Zapier from '../svgs/Zapier';
import useIntegrationsStore from '../store/useIntegrationsStore';

import { ReactComponent as Trustpilot } from '../svgs/trustpilot-2.svg';
import { ReactComponent as Typeform } from '../svgs/typeform.svg';
import { ReactComponent as Drift } from '../svgs/drift.svg';
import { ReactComponent as Freshdesk } from '../svgs/freshdesk.svg';
import { ReactComponent as G2 } from '../svgs/g2-reviews.svg';
import { ReactComponent as Qualaroo } from '../svgs/qualaroo.svg';
import { ReactComponent as Capterra } from '../svgs/capterra.svg';
import { ReactComponent as Survicate } from '../svgs/survicate.svg';
import { ReactComponent as Slack } from '../svgs/slack.svg';
import { ReactComponent as Front } from '../svgs/front.svg';
import { ReactComponent as GetFeedback } from '../svgs/getfeedback.svg';
import ReviewsIo from '../svgs/reviews.io.png';

export const getName = (type) => {
  switch (type) {
    case INTEGRATION_TYPE.INTERCOM:
      return 'Intercom';
    case INTEGRATION_TYPE.CHAT_FEEDBACK_UPLOAD:
      return 'Feedback Upload';
    case INTEGRATION_TYPE.SURVEY_UPLOAD:
      return 'Survey Upload';
    case INTEGRATION_TYPE.REVIEW_UPLOAD:
      return 'Reviews Upload';
    case INTEGRATION_TYPE.ZENDESK_SUPPORT:
      return 'Zendesk Support';
    case INTEGRATION_TYPE.TRUSTPILOT:
      return 'Trustpilot';
    case INTEGRATION_TYPE.TYPEFORM:
      return 'Typeform';
    case INTEGRATION_TYPE.DRIFT:
      return 'Drift';
    case INTEGRATION_TYPE.FRESHDESK:
      return 'Freshdesk';
    case INTEGRATION_TYPE.QUALAROO:
      return 'Qualaroo';
    case INTEGRATION_TYPE.REVIEWS_IO:
      return 'Reviews.io';
    case INTEGRATION_TYPE.G2:
      return 'G2';
    case INTEGRATION_TYPE.SURVICATE:
      return 'Survicate';
    case INTEGRATION_TYPE.CAPTERRA:
      return 'Capterra';
    case INTEGRATION_TYPE.FRONT:
      return 'Front';
    case INTEGRATION_TYPE.SLACK:
      return 'Slack';
    case INTEGRATION_TYPE.HUBSPOT_CHAT:
      return 'HubSpot Chat';
    case INTEGRATION_TYPE.ZENDESK_CHAT:
      return 'Zendesk Chat';
    case INTEGRATION_TYPE.GET_FEEDBACK:
      return 'GetFeedback';
    case INTEGRATION_TYPE.ZAPIER:
      return 'Zapier';
    default:
      return '';
  }
};

export const getSubType = (type, loading) => {
  if (loading) {
    return 'Gathering and processing data...';
  }

  switch (type) {
    case INTEGRATION_TYPE.INTERCOM:
    case INTEGRATION_TYPE.DRIFT:
    case INTEGRATION_TYPE.SLACK:
    case INTEGRATION_TYPE.HUBSPOT_CHAT:
    case INTEGRATION_TYPE.ZENDESK_CHAT:
      return 'Chat';
    case INTEGRATION_TYPE.CHAT_FEEDBACK_UPLOAD:
    case INTEGRATION_TYPE.SURVEY_UPLOAD:
    case INTEGRATION_TYPE.REVIEW_UPLOAD:
      return 'CSV/Spreadsheet';
    case INTEGRATION_TYPE.ZENDESK_SUPPORT:
      return 'Tickets';
    case INTEGRATION_TYPE.TRUSTPILOT:
    case INTEGRATION_TYPE.REVIEWS_IO:
    case INTEGRATION_TYPE.G2:
    case INTEGRATION_TYPE.CAPTERRA:
      return 'Reviews';
    case INTEGRATION_TYPE.TYPEFORM:
    case INTEGRATION_TYPE.QUALAROO:
    case INTEGRATION_TYPE.SURVICATE:
      return 'Surveys';
    case INTEGRATION_TYPE.FRESHDESK:
      return 'CSAT';
    case INTEGRATION_TYPE.FRONT:
      return 'Ticketing';
    case INTEGRATION_TYPE.GET_FEEDBACK:
    case INTEGRATION_TYPE.ZAPIER:
      return 'Multichannel';
    default:
      return '';
  }
};

export const getGraphic = (type) => {
  switch (type) {
    case INTEGRATION_TYPE.INTERCOM: return <Intercom />;
    case INTEGRATION_TYPE.CHAT_FEEDBACK_UPLOAD:
    case INTEGRATION_TYPE.SURVEY_UPLOAD:
    case INTEGRATION_TYPE.REVIEW_UPLOAD: return <Csv />;
    case INTEGRATION_TYPE.ZENDESK_SUPPORT: return <Zendesk />;
    case INTEGRATION_TYPE.ZENDESK_CHAT: return <ZendeskInactive />;
    case INTEGRATION_TYPE.TRUSTPILOT: return <Trustpilot />;
    case INTEGRATION_TYPE.TYPEFORM: return <Typeform className="rounded-md" />;
    case INTEGRATION_TYPE.DRIFT: return <Drift />;
    case INTEGRATION_TYPE.FRESHDESK: return <Freshdesk />;
    case INTEGRATION_TYPE.QUALAROO: return <Qualaroo />;
    case INTEGRATION_TYPE.REVIEWS_IO: return (
      <img
        style={{ width: 60 }}
        src={ReviewsIo}
        alt="ReviewsIo"
      />
    );
    case INTEGRATION_TYPE.G2: return <G2 />;
    case INTEGRATION_TYPE.SURVICATE: return <Survicate />;
    case INTEGRATION_TYPE.CAPTERRA: return <Capterra />;
    case INTEGRATION_TYPE.FRONT: return <Front />;
    case INTEGRATION_TYPE.SLACK: return <Slack />;
    case INTEGRATION_TYPE.HUBSPOT_CHAT: return <Hubspot />;
    case INTEGRATION_TYPE.GET_FEEDBACK: return <GetFeedback />;
    case INTEGRATION_TYPE.ZAPIER: return <Zapier />;
    default: return null;
  }
};

export const getH2 = (type) => {
  switch (type) {
    case INTEGRATION_TYPE.INTERCOM: return 'Sync customer feedback with Intercom';
    case INTEGRATION_TYPE.TYPEFORM: return 'Sync customer feedback with Typeform';
    case INTEGRATION_TYPE.ZENDESK_SUPPORT: return 'Sync customer feedback with Zendesk Support';
    case INTEGRATION_TYPE.CHAT_FEEDBACK_UPLOAD: return 'Sync customer feedback from CSV or Excel files';
    case INTEGRATION_TYPE.REVIEW_UPLOAD: return 'Sync customer Reviews';
    case INTEGRATION_TYPE.SURVEY_UPLOAD: return 'Sync customer Surveys';
    case INTEGRATION_TYPE.ZAPIER: return 'Sync customer feedback with Zapier';
    default: return '';
  }
};

export const getDescription = (type) => {
  const defaultDescription = 'ClientZen helps you replace the manual tagging process and gives you deeper customer insights with pain points\n'
    + 'discovery, MRR and sentiment analysis. Get all your customer feedback automatically tagged by topic and sentiment\n'
    + 'with the most accurate feedback analytics software.';
  switch (type) {
    case INTEGRATION_TYPE.INTERCOM:
    case INTEGRATION_TYPE.ZAPIER:
    case INTEGRATION_TYPE.ZENDESK_SUPPORT: return defaultDescription;
    case INTEGRATION_TYPE.TYPEFORM: return 'Connect Typeform to make sure that valuable customer feedback is never lost.<br /><br />ClientZen helps you replace the slow manual tagging process and gives you deeper customer insights with pain points discovery, MRR and sentiment analysis.';
    case INTEGRATION_TYPE.CHAT_FEEDBACK_UPLOAD: return 'Upload spreadsheets of surveys, reviews or any customer feedback easily. Don\'t worry about setting up your columns beforehand. You will be able to match conversations, dates, names, ids and any number of custom fields. <br /><br /> ClientZen helps you replace the slow manual tagging process and gives you deeper customer insights with pain points discovery, MRR and sentiment analysis.';
    case INTEGRATION_TYPE.REVIEW_UPLOAD: return `${defaultDescription}<br /><br /> The first column in the csv file must contain the survey text.`;
    case INTEGRATION_TYPE.SURVEY_UPLOAD: return `${defaultDescription}<br /><br /> The first column in the csv file must contain the review text.`;
    default: return '';
  }
};

export const isLoading = (type, state) => {
  switch (type) {
    case 'zendesk':
    case 'intercom':
    case 'typeform':
      return state.connectedPlatforms.find((p) => p.type === type)?.status === 'initial' || false;
    case 'chat_feedback_upload':
    case 'survey_upload':
    case 'review_upload':
      return false;
    default:
      return false;
  }
};

const IntegrationCard = ({
  type, tooltip,
}) => {
  const {
    state, actions: {
      highlightIntercom,
      highlightZendeskSupport,
      highlightChatFeedbackUpload,
      highlightSurveyUpload,
      highlightReviewUpload,
      highlightZapier,
      highlightTypeform,
    },
  } = useIntegrationsStore();
  const loading = isLoading(type, state);

  const onClick = () => {
    if (loading) {
      return;
    }

    switch (type) {
      case INTEGRATION_TYPE.INTERCOM:
        highlightIntercom();
        break;
      case INTEGRATION_TYPE.TYPEFORM:
        highlightTypeform();
        break;
      case INTEGRATION_TYPE.ZENDESK_SUPPORT:
        highlightZendeskSupport();
        break;
      case INTEGRATION_TYPE.CHAT_FEEDBACK_UPLOAD:
        highlightChatFeedbackUpload();
        break;
      case INTEGRATION_TYPE.SURVEY_UPLOAD:
        highlightSurveyUpload();
        break;
      case INTEGRATION_TYPE.REVIEW_UPLOAD:
        highlightReviewUpload();
        break;
      case INTEGRATION_TYPE.ZAPIER:
        highlightZapier();
        break;
      default:
        highlightZapier();
        // Modal.info({
        //   icon: null,
        //   closable: true,
        //   okText: null,
        //   content: <iframe title="hubspot" src="https://meetings.clientzen.io/meetings/ovi/30mins?embed=true" />,
        //   className: 'hubspot-modal',
        //   width: 850,
        // });
    }
  };

  return (
    <div className="integrations__card">
      <Tooltip title={tooltip}>
        <Card onClick={onClick} hoverable={!loading}>
          {
            loading ? <div className="integrations__loader" /> : getGraphic(type)
          }
          <h4>{getName(type)}</h4>
          <h5>{getSubType(type, loading)}</h5>
        </Card>
      </Tooltip>
    </div>
  );
};

export default IntegrationCard;
