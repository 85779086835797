import React, { useEffect, useState } from 'react';
import IconSentimentNegativeSmall from '@/images/sentiments/icon-sentiment-negative-small.svg';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { Empty, Spin } from 'antd';
import { conversations } from '@/api';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Card from '@/components/card';
import { cn } from '@/helpers/util';

const getSummaryText = (item) => {
  const choices = ['users', 'customers'];
  const principal = choices[(Math.floor(Math.random() * choices.length))];
  switch (item.intent) {
    case 'issue': return item.summary.indexOf('facing') !== -1 ? item.summary : `${principal} are facing ${item.summary}`;
    case 'question': return item.summary.indexOf('ask') !== -1 ? item.summary : `${principal} are asking about the ${item.summary}`;
    case 'request': return item.summary.indexOf('request') !== -1 ? item.summary : `${principal} are requesting information about the ${item.summary}`;
    default: return item.summary;
  }
};

function DriverList({ topicId }) {
  const [loading, setLoading] = useState(true);
  const [collapse, setCollapse] = useState(false);
  const [list, setResults] = useState([]);

  const CollapseButton = () => {
    if (!collapse) {
      if (list.length > 1) {
        return (
          <button
            onClick={() => setCollapse(true)}
            type="button"
            className="btn-collapse"
          >
            <DownOutlined />
            {' '}
            +
            {list.length - 1}
            {' '}
            more insights
          </button>
        );
      } return null;
    }
    return (
      <button
        onClick={() => setCollapse(false)}
        type="button"
        className="btn-collapse"
      >
        <UpOutlined />
        {' '}
        Collapse
      </button>
    );
  };

  useEffect(() => {
    let intervalId = null;

    const getSummaryResults = async (id) => {
      const summaryResults = await conversations.getSummaryResult(id);
      if (summaryResults.data.status === 'completed' && !summaryResults.data.results.length) {
        setResults([]);
        setLoading(false);
        clearInterval(intervalId);
      } else if (summaryResults.data.results.length) {
        setResults(summaryResults.data.results.sort((a, b) => b.count - a.count).slice(0, 6));
        setLoading(false);
        clearInterval(intervalId);
      }

      return summaryResults;
    };

    const generateSummary = async () => {
      try {
        const summaryRequest = await conversations.getSummary(
          topicId,
          moment().subtract(30, 'days').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
          undefined,
          undefined,
          undefined,
          ['negative'],
        );

        const summaryResults = await getSummaryResults(summaryRequest.data.data.id);
        if (!summaryResults.data.results.length) {
          intervalId = setInterval(async () => {
            getSummaryResults(summaryRequest.data.data.id);
          }, 7000);
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    };

    generateSummary();

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <ul className="custom-list-sentiment-drivers">
        {loading ? (
          <Spin />
        ) : null}

        {
          !loading && list.length ? (
            <li>{getSummaryText(list[0])}</li>
          ) : null
        }

        {collapse
          ? list
            .slice(1, list.length)
            .map((item) => <li key={item.id}>{getSummaryText(item)}</li>)
          : null}
      </ul>
      {(!loading && list.length > 0) ? (
        <CollapseButton />
      ) : null}
    </>
  );
}

function SentimentItem({
  id, groupId, title, items, value,
}) {
  return (
    <li className="sentiment-drivers-item">
      <div className="sentiment-drivers-group">
        <Link to={`/topic/overview/${id}/${groupId}`}>
          <h3>{title}</h3>
        </Link>
        <DriverList topicId={id} data={items} />
      </div>
      <div className="sentiment-drivers-icon">
        <img src={IconSentimentNegativeSmall} alt="icon" />
        <span className="sentiment-drivers-icon-text">
          +
          {value}
        </span>
      </div>
    </li>
  );
}

function SentimentList({ data }) {
  return (
    <ul className="sentiment-drivers-list">
      {data.map((item) => (
        <SentimentItem
          key={item.id}
          id={item.id}
          groupId={item.group_id}
          title={item.name}
          items={[
            {
              description:
                'Users are complaining about inability to find Invoices for the last quarter.',
            },
          ]}
          value={item.value}
        />
      ))}
    </ul>
  );
}

function SentimentChart({ data }) {
  function transformData(date) {
    return date.map(({ name, ...rest }) => ({
      name: moment(name).format('DD MMM'),
      ...rest,
    }));
  }

  return (
    <div className="sentiment-drivers-chart" style={{ marginRight: '20px' }}>
      <ResponsiveContainer width="100%" height={260}>
        <LineChart
          data={transformData(data)}
          margin={{
            bottom: 48,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis tickLine={false} stroke={0} dataKey="name">
            {/* <Label
              className="sentiment-drivers-chart-label"
              value="Churn prediction led by negative sentiment"
              position="bottom"
              offset={32}
            /> */}
          </XAxis>
          <YAxis tickLine={false} axisLine={false} />
          <Legend
            formatter={(value) => (
              <span style={{ color: '#595959' }}>{value}</span>
            )}
            verticalAlign="top"
            iconType="plainline"
            wrapperStyle={{ top: '-32px' }}
          />
          <Line
            id="nsd"
            dataKey="nsd"
            name="Negative Sentiment Drivers"
            stroke="#FFA39E"
            strokeWidth={2}
            legendType="line"
            dot={false}
          />
          <Line
            id="churn"
            dataKey="churn"
            name="Churn"
            stroke="#D26262"
            strokeWidth={2}
            legendType="line"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export function SentimentDriversCard({ sentiments, className }) {
  return (
    <Card className={cn('pt-[16px] pb-4 mb-4', className)}>
      <Card.Header>
        <div className="flex items-center divide-y-0 divide-x-2 gap-7 divide-solid divide-[#DFE9F1]">
          <Card.Header.Title title="Negative Sentiment Drivers" />
        </div>
      </Card.Header>
      <Card.Body className="mt-6 grid grid-cols-2">
        {!sentiments.top.length ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No data over the selected period"
          />
        ) : (
          <SentimentList data={sentiments.top} />
        )}
        <SentimentChart data={sentiments.chart} />
      </Card.Body>
    </Card>
  );
}
