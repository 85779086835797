import React, { useState } from 'react';
import PeopleTable from '@/base-components/people-table';
import { Helmet } from 'react-helmet';
import ExportButton from './components/ExportButton';
import SegmentSelect from './components/SelectSegment/SelectSegment';
import useFilters from './useFilters';
import SearchInput from './components/SearchInput';
import { inDevelopment } from '../../flags';
import Layout from '../Layout';
import { SentimentDateFilter } from '../ui/sentiment-date-filter';

const People = () => {
  const {
    sorts, setSort, setSearch, tableFilters, setTableFilter, filters,
  } = useFilters();

  const [segmentValue, setSegmentValue] = useState(undefined);

  return (
    <Layout
      leftHeader={(
        <>
          <SearchInput search={filters.name} setSearch={setSearch} />
          {
            inDevelopment() ? (
              <SegmentSelect
                value={segmentValue}
                onChange={(value) => setSegmentValue(value)}
                filters={filters}
                sorts={sorts}
              />
            ) : null
          }
        </>
      )}
      rightHeader={(
        <>
          {inDevelopment() ? (
            <ExportButton filters={filters} />
          ) : null}
          <SentimentDateFilter />
        </>
      )}
    >
      <Helmet>
        <title>ClientZen - People</title>
      </Helmet>
      <section className="people mx-7 2xl:mx-auto 2xl:max-w-[1200px]">

        <div className="people__body">
          <PeopleTable
            perPage={10}
            filters={filters}
            tableFilters={tableFilters}
            setTableFilter={setTableFilter}
            setSort={setSort}
            sorts={sorts}
          />
        </div>
      </section>
    </Layout>
  );
};

export default People;
