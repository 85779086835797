import React, { useEffect, useMemo } from 'react';
import Card from '@/components/card';
import { Button, List, message } from 'antd';
import { cn } from '@/helpers/util';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { captureException } from '@sentry/react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import useSummary from '../discovery/hooks/useSummary';
import { getSummaryText } from '../summary/SummaryResults';
import { getResultIcon } from '../summary/SummaryResultItem';

function getSummaryData(data) {
  const results = data?.results ?? [];

  return results.sort((a, b) => b.count - a.count);
}

function InsightItem({ item, total }) {
  const percent = Math.ceil((item.count / total) * 100);
  const computedText = useMemo(() => getSummaryText(item), []);
  const formattedText = `${percent}% ${computedText.split(' ')[0] === 'users' ? 'of' : ''} ${computedText}`;
  return (
    <List.Item className="cz-saved-filters !p-0">
      <List.Item.Meta
        className="!gap-4  !items-start [&_.ant-list-item-meta-avatar]:mt-0.5 [&_.ant-list-item-meta-avatar]:flex [&_.ant-list-item-meta-description]:text-[#44596C] [&_.ant-list-item-meta-description]:font-[Gordita-Medium]"
        avatar={getResultIcon(item.intent)}
        description={formattedText}
      />
    </List.Item>
  );
}

export function KeyInsightsCard({ className }) {
  const { defaultDate } = useDefaultDateStore();
  const {
    data, isLoading, isError, error,
  } = useSummary({
    start: defaultDate.start,
    end: defaultDate.end,
  });

  const total = useMemo(() => data?.results.reduce((val, item) => val + item.count, 0), [data]);
  const results = useMemo(() => {
    const summaryResults = getSummaryData(data);
    return summaryResults.filter((item) => item.intent !== 'other').slice(0, 3);
  }, [data]);

  useEffect(() => {
    if (!isError) return;

    message.error(error.message);
    captureException(error);
  }, [isError]);

  return (
    <Card className={cn('pt-[16px] pb-8 mb-4', className)}>
      <Card.Header>
        <div className="flex items-center divide-y-0 divide-x-2 gap-7 divide-solid divide-[#DFE9F1]">
          <Card.Header.Title title="Key Insights" />
        </div>
        <Card.Header.Actions>
          <Link to="/discovery?insights=true">
            <Button className="ant-btn ant-btn-secondary mt-[-2px]">
              View all
            </Button>
          </Link>
        </Card.Header.Actions>
      </Card.Header>
      <Card.Body className="mt-6">
        <List
          className="[&_.ant-list-items]:space-y-6"
          loading={isLoading}
          dataSource={results}
          renderItem={(item) => <InsightItem item={item} total={total} />}
        />
      </Card.Body>
    </Card>
  );
}
