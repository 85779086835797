import React, { useCallback, useEffect } from 'react';
import { notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import IntegrationList from './IntegrationList';
import { INTEGRATION_TYPE } from '../constants';
import IntegrationPopup from './IntegrationPopup';
import IntegrationExtendedCard from './IntegrationExtendedCard';
import useIntegrationsStore from '../store/useIntegrationsStore';
import Zapier from '../svgs/Zapier';

const freeTrialPlatforms = [
  INTEGRATION_TYPE.INTERCOM,
  INTEGRATION_TYPE.ZENDESK_SUPPORT,
  INTEGRATION_TYPE.TYPEFORM,
  // INTEGRATION_TYPE.ZAPIER,
  INTEGRATION_TYPE.CHAT_FEEDBACK_UPLOAD,
  // INTEGRATION_TYPE.SURVEY_UPLOAD,
  // INTEGRATION_TYPE.REVIEW_UPLOAD,
];
const premiumPlatforms = [
  INTEGRATION_TYPE.TRUSTPILOT,
  INTEGRATION_TYPE.DRIFT,
  INTEGRATION_TYPE.FRESHDESK,
  INTEGRATION_TYPE.G2,
  INTEGRATION_TYPE.QUALAROO,
  INTEGRATION_TYPE.REVIEWS_IO,
  INTEGRATION_TYPE.CAPTERRA,
  INTEGRATION_TYPE.SURVICATE,
  INTEGRATION_TYPE.SLACK,
  INTEGRATION_TYPE.FRONT,
  INTEGRATION_TYPE.GET_FEEDBACK,
];

export const GATHERING_DATA_INTEGRATIONS = 'gathering-data-integrations';

const Integrations = ({ hasDashboardNotification, hasBackground, hasH1 }) => {
  const history = useHistory();
  const { state, actions } = useIntegrationsStore();
  const connectedPlatforms = state.connectedPlatforms
    .map((platform) => platform.type)
    .filter((platform) => freeTrialPlatforms.includes(platform));
  const readyPlatforms = state.connectedPlatforms
    .filter((platform) => platform.status !== 'not_ready')
    .map((platform) => platform.type)
    .filter((platform) => freeTrialPlatforms.includes(platform));

  const showGatheringData = useCallback(() => {
    notification.open({
      className: 'gathering-data-integrations',
      closeIcon: null,
      description: <IntegrationPopup history={history} actions={actions} />,
      duration: null,
      icon: null,
      placement: 'bottomRight',
      key: GATHERING_DATA_INTEGRATIONS,
    });
  }, [history]);

  useEffect(() => {
    if (hasDashboardNotification && readyPlatforms.length) {
      showGatheringData();
    }
  }, [hasDashboardNotification, readyPlatforms]);

  return (
    <>
      {hasH1 && !state.highlightedPlatform && (
        <div>
          <h1>Connect Platforms</h1>
          <p>
            <span>Connect your customer feedback tools or</span>
            <br />
            <Link to="/demo" className="underline">
              explore our demo account
            </Link>
            <span> until you&apos;re ready</span>
          </p>
        </div>
      )}
      <div
        className={classNames('integrations', {
          has_background: hasBackground,
        })}
      >
        <div
          className={classNames('integrations__wrap', {
            has_background: hasBackground,
          })}
        >
          {state.highlightedPlatform ? (
            <IntegrationExtendedCard type={state.highlightedPlatform} />
          ) : (
            <>
              {connectedPlatforms.length ? (
                <IntegrationList
                  platforms={connectedPlatforms}
                  title="Connected Platforms"
                />
              ) : null}
              <IntegrationList
                platforms={freeTrialPlatforms.filter(
                  (platform) => !state.connectedPlatforms.find(
                    (connectedPlatform) => connectedPlatform.type === platform,
                  ),
                )}
              />
              <IntegrationList
                platforms={premiumPlatforms}
                title={(
                  <div className="integration-title-icon">
                    <Zapier />
                    Connect through Zapier
                  </div>
                )}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Integrations;
