import React, { useReducer, useEffect } from 'react';
import { Table, message } from 'antd';
import { useHistory, withRouter } from 'react-router-dom';
import TopicMetric from '@/base-components/topic-metric';
import { clients } from '@/api';
import { buildSorterQuery } from '../../../helpers/filters';
import {
  initialState,
  peopleTableReducer,
  SET_DATA_ACTION,
  SET_LOADING_ACTION,
  SET_SORTER_ACTION,
} from '../peopleTableReducer';
import TablePagination from '../../table-pagination';
import RenewalFilter from './filters/RenewalFilter';
import UserTypeFilter from './filters/UserTypeFilter';
import SentimentFilter from './filters/SentimentFilter';
import SentimentColumn from './sentiment-columns/SentimentColumn';
import useURLState from '../../../hooks/useUrlState';

const PeopleTable = ({
  filters, perPage, tableFilters, setTableFilter, sorts, setSort, location: { pathname },
}) => {
  const [{
    data, loading, reload, pagination, sorter,
  }, dispatch] = useReducer(peopleTableReducer, initialState);

  const { urlQueryState, setUrlQueryParam } = useURLState();

  const getPeople = async (page) => {
    dispatch({ type: SET_LOADING_ACTION, payload: true });

    if (page !== urlQueryState.page) {
      setUrlQueryParam('page', page);
    }

    clients
      .index(filters, page, sorter, perPage)
      .then(({ data: response }) => {
        dispatch({ type: SET_DATA_ACTION, payload: response });
      })
      .catch(() => {
        dispatch({ type: SET_LOADING_ACTION, payload: false });

        message.error('People Error');
      });
  };

  const history = useHistory();

  useEffect(() => {
    getPeople(urlQueryState.page ? urlQueryState.page : 1);
  }, [filters, sorter, reload, perPage, urlQueryState.page]);

  return (
    <Table
      rowKey={({ id }) => id}
      className="people-table"
      scroll={{ x: 'max-content' }}
      loading={loading}
      dataSource={data}
      showSorterTooltip={false}
      pagination={false}
      footer={() => <TablePagination type="people" pagination={pagination} onPageChange={getPeople} />}
      onChange={(_currentPagination, _currentFilters, currentSorter, extra) => {
        const { action } = extra;
        if (action === 'sort') {
          if (Array.isArray(currentSorter)) {
            const lastSorter = currentSorter[currentSorter.length - 1];
            setSort(lastSorter.field, lastSorter.order);
          } else {
            setSort(currentSorter.field, currentSorter.order);
          }
        }
        dispatch({ type: SET_SORTER_ACTION, payload: buildSorterQuery(currentSorter) });
      }}
    >
      <Table.Column
        title="People"
        dataIndex="name"
        key="name"
        align="left"
        style={{ cursor: 'pointer' }}
        className="column-clickable"
        onCell={(record) => ({
          onClick: () => {
            history.push(`${pathname.startsWith('/demo') ? '/demo/' : '/'}people/${record.id}`);
          },
        })}
      />
      <Table.Column
        title="Conversations"
        dataIndex="conversations"
        key="conversations"
        align="left"
        sorter={{ multiple: 1 }}
        sortOrder={sorts.conversations}
        render={({ value, trend }) => (
          <TopicMetric style={{ color: '#262626', fontSize: 16, gap: 6 }} value={value} trend={trend} />
        )}
      />
      <Table.Column
        title="Segments"
        dataIndex="topics"
        key="topics"
        align="left"
        sorter={{ multiple: 1 }}
        sortOrder={sorts.topics}
      />
      <Table.Column
        title="MRR"
        dataIndex="mrr"
        key="mrr"
        align="left"
        sorter={{ multiple: 1 }}
        sortOrder={sorts.mrr}
        onFilter={(value) => value}
        render={({ value }) => (
          <TopicMetric.MRR
            style={{ color: '#262626', fontSize: 16, gap: 6 }}
            value={value}
            tooltip={`$${Math.round(value)} estimated monthly revenue by this user`}
          />
        )}
      />
      <Table.Column
        title="Renewal"
        dataIndex="renewal"
        key="renewal"
        align="left"
        render={(value) => <TopicMetric.Renewal style={{ color: '#262626', fontSize: 16, gap: 6 }} value={value} />}
        filterDropdown={(props) => (
          <RenewalFilter
            filterValue={filters.renewal}
            tableFilters={tableFilters}
            setTableFilter={setTableFilter}
            {...props}
          />
        )}
      />
      <Table.Column
        title="User Type"
        dataIndex="user_type"
        key="user_type"
        align="left"
        render={(value) => (value ? value.charAt(0).toUpperCase() + value.slice(1) : null)}
        filterDropdown={(props) => (
          <UserTypeFilter
            filterValue={filters.userType}
            setTableFilter={setTableFilter}
            {...props}
          />
        )}
      />
      <Table.Column
        title="Sentiment"
        dataIndex="sentiment"
        key="sentiment"
        align="left"
        render={({ positive, negative }) => (
          <SentimentColumn positive={positive} negative={negative} />
        )}
        filterDropdown={(props) => (
          <SentimentFilter
            sentiment={filters.sentiment}
            sentimentIntensity={filters.sentimentIntensity}
            sentimentIntensityPercentage={filters.sentimentIntensityPercentage}
            tableFilters={tableFilters}
            setTableFilter={setTableFilter}
            {...props}
          />
        )}
      />
    </Table>
  );
};

export default withRouter(PeopleTable);
