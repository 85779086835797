import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { TeamOutlined } from '@ant-design/icons';
import { recentInteractions } from '@/api';
import AddAllButton from './RecentInteractions/AddAllButton';
import ContactSelect from './ContactSelect';
import InteractionCard from './InteractionCard';
import { displayOrCatchMessage } from '../../helpers';

const SearchContacts = ({
  persons,
  addPerson,
  removePerson,
  onDomain,
  tags,
  isColleague,
  hasAddAllButton,
  recentInteractionAddAll,
}) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const cancelTokenRef = useRef(null);

  useEffect(() => {
    if (searchValue) {
      setLoading(true);
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel();
      }
      setItems([]);
      cancelTokenRef.current = axios.CancelToken.source();
      recentInteractions.index(
        onDomain,
        searchValue,
        cancelTokenRef.current.token,
        3,
      ).then((response) => {
        setItems(response.data.data);
        setLoading(false);
      }).catch((e) => {
        if (!axios.isCancel(e)) {
          displayOrCatchMessage(e);
          setLoading(false);
        }
      });
    } else if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel();
      setLoading(false);
    }
  }, [searchValue]);

  return (
    <>
      <div className="graphic-container__heading">
        <TeamOutlined />
        <span>Search in your contact list</span>
      </div>
      <div className="graphic-container__clients">
        <div style={{ display: 'flex' }}>
          <ContactSelect
            loading={loading}
            items={items}
            selectedItems={persons}
            onSelect={addPerson}
            onDeselect={removePerson}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            tags={tags}
            onDomain={onDomain}
          />
          {
            hasAddAllButton ? (
              <AddAllButton recentInteractionAddAll={recentInteractionAddAll} />
            ) : null
          }
        </div>

        {
          !tags ? persons.filter((person) => !person.recent).map((person) => (
            <InteractionCard
              key={person.email}
              {...person}
              onDomain={onDomain}
              isColleague={isColleague}
            />
          )) : null
        }
      </div>
    </>
  );
};

export default SearchContacts;
