import React, { useEffect, useState } from 'react';
import { Checkbox, Input, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import useApi from '@/hooks/useApi';
import { clients } from '@/api';
import { displayOrCatchMessage } from '@/helpers';

const getData = async (search) => {
  try {
    return await clients.types(search);
  } catch (e) {
    displayOrCatchMessage(e);
    return [];
  }
};

const UserTypeFilter = ({ filterValue, setTableFilter, confirm }) => {
  const [search, setSearch] = useState(undefined);
  const {
    data, run, isLoading, isSuccess,
  } = useApi(getData, { runOnMount: true });

  const searchProps = {
    className: 'search',
    placeholder: 'Search',
    suffix: <SearchOutlined />,
    onChange: debounce((e) => {
      setSearch(e.target.value);
    }, 400),
  };

  useEffect(() => {
    run(search);
  }, [search]);

  // eslint-disable-next-line no-shadow
  const filterUserType = (confirm, userType) => {
    setTableFilter('userType', filterValue === userType ? undefined : userType);
    confirm({ closeDropdown: true });
  };

  return (
    <div className="user-type-filter">
      <Input {...searchProps} size="small" />
      {isLoading && <Spin size="small" />}
      {isSuccess && (
      <div className="checkboxes">
        {data.map((option) => (
          <Checkbox
            key={option}
            checked={filterValue === option}
            onChange={() => filterUserType(confirm, option)}
          >
            {option}
          </Checkbox>
        ))}
      </div>
      )}
    </div>
  );
};

export default UserTypeFilter;
