import React from 'react';
import { Modal, Button } from 'antd';
import AnnotationsTable from '@/base-components/annotations-table';
import { useParams } from 'react-router-dom';
import { useModalStore } from '@/store/zustand/useModalStore';

const ReleaseEditModal = ({
  visible, onOk, onCancel,
}) => {
  const { payload } = useModalStore();
  const { topicId } = useParams();
  const annotationsTableProps = {
    onDelete: onOk,
    filters: { topic_id: topicId || payload },
    perPage: 5,
  };

  return (
    <Modal
      title="Releases"
      visible={visible}
      className="list-modal"
      width={650}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <Button
          key="done"
          type="primary"
          size="large"
          onClick={onCancel}
        >
          Done
        </Button>,
      ]}
    >
      <AnnotationsTable {...annotationsTableProps} />
    </Modal>
  );
};

export default ReleaseEditModal;
