/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, {
  useEffect, useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { Spin, message } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Layout from '@/components/Layout';
import { Trend } from '@/base-components/topic-metric';
import SentimentAndCountLines from '@/components/dashboard/SentimentAndCountLines';
import { SentimentDriversCard } from '@/components/sentiment-drivers-card';
import moment from 'moment';
import customerIo from '@/customer-io';
import { SentimentDateFilter } from '@/components/ui/sentiment-date-filter';
import Card from '@/components/card';
import { StatsCard } from '@/components/bucket/BucketContainer';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { useDashboard } from '@/data/useDashboard';
import { TrendingCard } from '@/components/trending-card';
import { KeyInsightsCard } from '@/components/key-insights-card';
import { SourcesStats } from '@/components/sources/SourcesStats';
import { prefetchSources } from '@/data/useSources';
import { ConnectPopup } from '@/components/connect-popup';

export default function DashboardPage() {
  const { defaultDate } = useDefaultDateStore();
  const isWeek = moment(defaultDate.end).diff(moment(defaultDate.start), 'months') >= 3 ? 1 : 0;

  prefetchSources();
  const {
    data, isLoading, isError, error,
  } = useDashboard({
    start: defaultDate.start,
    end: defaultDate.end,
    week: isWeek,
  });

  useEffect(() => {
    if (!isError) return;
    message.error(error.message);
  }, [error]);

  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (import.meta.env.VITE_CUSTOMERIO_SITEID) {
      customerIo.track('dashboard-loaded');
    }
  }, []);

  if (isLoading) {
    return (
      <Layout rightHeader={(<SentimentDateFilter />)}>
        <Helmet titleTemplate="%s - ClientZen" title="Home" />
        <div className="mx-auto max-w-[1200px]">
          <div className="pt-24 text-center">
            <Spin size="large" />
          </div>
        </div>
      </Layout>
    );
  }

  let neutral = data
    ? parseInt(data.sentiment_counts.total, 10)
  - parseInt(data.sentiment_counts.positive, 10)
  - parseInt(data.sentiment_counts.negative, 10)
    : 0;
  let previousNeutral = data
    ? parseInt(data.previous_sentiment_counts.total, 10)
  - parseInt(data.previous_sentiment_counts.positive, 10)
  - parseInt(data.previous_sentiment_counts.negative, 10)
    : 0;
  if (!neutral) {
    neutral = 0;
  }
  if (!previousNeutral) {
    previousNeutral = 0;
  }

  const feedback = {
    value: data.sentiment_counts.total === null ? 0 : parseInt(data.sentiment_counts.total, 10),
    trend: data.sentiment_counts.total > data.previous_sentiment_counts ? Trend.ASC : Trend.DESC,
    trendValue: data.sentiment_counts.total && data.previous_sentiment_counts.total
      ? parseInt(data.sentiment_counts.total, 10) > parseInt(data.previous_sentiment_counts.total, 10)
        ? parseInt(data.sentiment_counts.total, 10) - parseInt(data.previous_sentiment_counts.total, 10)
        : parseInt(data.previous_sentiment_counts.total, 10) - parseInt(data.sentiment_counts.total, 10)
      : 0,
    link: '/discovery',
  };

  return (
    <Layout rightHeader={(<SentimentDateFilter />)}>
      <Helmet titleTemplate="%s - ClientZen" title="Home" />
      <div className="mx-7 2xl:mx-auto 2xl:max-w-[1200px] new-dashboard-page">
        <ConnectPopup className="mb-4" />
        <Card className="py-4 mb-4 grid grid-cols-2">
          <StatsCard {...feedback} />
          <SourcesStats />
        </Card>
        <section className="grid grid-cols-2 gap-4">
          <KeyInsightsCard />
          <TrendingCard topics={data.most_discussed_topics} total={data.sentiment_counts.total} />
        </section>
        <SentimentDriversCard sentiments={data.sentiment_drivers} />
        {data.most_vocal_segments.length ? (
          <SentimentAndCountLines
            name="Most Vocal Segments"
            color="#E8DEF5"
            hasPercentages
            data={data.most_vocal_segments}
            total={data.sentiment_counts.total}
            onRowClick={(row) => () => history.push(
              `${pathname.startsWith('/demo') ? '/demo/' : '/'
              }people?userType=${encodeURIComponent(row.name)}`,
            )}
          />
        ) : null}

        {data.top_issues.length || data.top_requests.length ? (
          <div className="new-dashboard-container-columns">
            {data.top_issues.length ? (
              <div className="column">
                <SentimentAndCountLines
                  name="Top Issues"
                  color="#F7E4E3"
                  data={data.top_issues}
                  total={data.sentiment_counts.total}
                />
              </div>
            ) : null}
            {data.top_requests.length ? (
              <div className="column">
                <SentimentAndCountLines
                  name="Top Requests"
                  color="#D5EBEB"
                  data={data.top_requests}
                  total={data.sentiment_counts.total}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </Layout>
  );
}
