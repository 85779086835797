import Layout from '@/components/Layout';
import { TaxonomyContainer } from '@/components/taxonomy';
import useTaxonomy from '@/components/taxonomy/hooks/useTaxonomy';
import { SentimentDateFilter } from '@/components/ui/sentiment-date-filter';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import ReleaseEditModal from '@/components/bucket/ReleaseEditModal';
import { useModalStore } from '@/store/zustand/useModalStore';
import { FiltersProvider } from '@/components/filter/context';
import EditModal from '@/components/bucket/EditModal';
import { useHistory } from 'react-router-dom';
import ModalTopicForm from '@/components/TopicForm/ModalTopicForm';
import { useSearchStore } from '@/store/zustand/useSearchStore';
import CreateDropdown from '@/components/taxonomy/components/CreateDropdown';
import CategoryModal from '@/components/categories/CategoryModal';

export function TaxonomyPage({ location: { pathname } }) {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { defaultDate } = useDefaultDateStore();
  const {
    payload, type, setModal, reset,
  } = useModalStore();
  const { search } = useSearchStore();
  const taxonomy = useTaxonomy({ start: defaultDate.start, end: defaultDate.end, search_value: search });

  const [openDrawer] = useState(false);

  /** Hide intercom when drawer is opened */
  useEffect(() => {
    if (!openDrawer) {
      window.Intercom('update', {
        hide_default_launcher: false,
      });
      return;
    }

    window.Intercom('update', {
      hide_default_launcher: true,
    });
  }, [openDrawer]);

  function handleOk() {
    queryClient.invalidateQueries({ queryKey: ['topic_graph'] });
  }

  const topicModalProps = {
    visible: type === 'create_taxonomy_topic',
    onCancel: () => reset(),
    onOk: (topic, isEdit) => {
      reset();
      if (!isEdit) {
        history.push(`${pathname.startsWith('/demo') ? '/demo/' : '/'}topic/overview/${topic.id}/${topic.groups[0].id}`);
      }
    },
  };

  const categoryModalProps = {
    visible: type === 'create_taxonomy_category',
    onCancel: () => reset(),
    onOk: () => {
      reset();
      queryClient.invalidateQueries({ queryKey: ['taxonomy'] });
    },
  };

  // const searchProps = {
  //   className: '!bg-[#EDF4F8] [&_.ant-input-prefix]:!mr-1.5 [&>input]:!bg-[#EDF4F8] !rounded-md [&>input]:!placeholder-[#A9BCCF] [&>input]:placeholder:!opacity-100 !border-0 font-[Gordita-Regular] hover:border hover:border-[#A9BCCF] focus:border focus:border-[#A9BCCF] focus:ring-0',
  //   placeholder: 'Search',
  //   prefix: <SearchIcon className="pr-0.5" size={16} strokeWidth={2} color="#44596C" />,
  //   onChange: debounce((e) => {
  //     setSearch(e.target.value);
  //   }, 400),
  // };

  if (taxonomy.isLoading) {
    return (
      <Layout
        title="Taxonomy"
        disableMantra
        rightHeaderClasses="items-center"
        // leftHeader={(<Input {...searchProps} />)}
        rightHeader={(
          <>
            <CreateDropdown />
            <SentimentDateFilter />
          </>
    )}>
        <div className="text-center p-6">
          <Spin />
        </div>
      </Layout>
    );
  }

  return (
    <Layout
      title="Taxonomy"
      disableMantra
      rightHeaderClasses="items-center"
      // leftHeader={(<Input {...searchProps} />)}
      rightHeader={(
        <>
          <CreateDropdown />
          {/* <Button
            className="flex gap-2.5 items-center"
            type="secondary"
            onClick={() => setOpenDrawer(true)}
          >
            <Settings2Icon
              size={16}
            />
            <span>Edit Taxonomy</span>
          </Button> */}
          <SentimentDateFilter />
        </>
    )}
    >
      <TaxonomyContainer />
      {/* <EditTaxonomyDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} /> */}

      <ModalTopicForm {...topicModalProps} />
      <CategoryModal {...categoryModalProps} />
      {
        type === 'edit_modal' ? (
          <FiltersProvider value={{ filters: payload?.filters?.actual, selectedFilters: payload?.filters?.applied }}>
            <EditModal visible onCancel={() => setModal(null)} />
          </FiltersProvider>
        ) : null
      }
      <ReleaseEditModal visible={type === 'edit_releases_modal'} onOk={handleOk} onCancel={() => setModal(null)} />
    </Layout>
  );
}
