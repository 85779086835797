import { conversations } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { transformCustomFieldToPost } from '@/api/conversations';

const sleep = (ms) => new Promise((resolve) => {
  setTimeout(() => resolve(), ms);
});

const getSummaryResult = async (summary) => {
  const results = await conversations.getSummaryResult(summary.data.data.id);

  if (results.data.status === 'completed') {
    const { data } = results;
    return { ...data, id: summary.data.data.id };
  }

  await sleep(4000);
  return getSummaryResult(summary);
};

export default function useSummary(filters) {
  return useQuery({
    queryKey: ['summary', { filters }],
    queryFn: async () => {
      const summary = await conversations.getSummary(
        filters.topic_id,
        filters.start,
        filters.end,
        transformCustomFieldToPost(filters.custom_fields),
        filters.subtopic_id,
        filters.keywords,
        filters.sentiment,
        filters.labels,
        filters.excluded_keywords,
        filters.sources,
        filters.days,
        filters.exclude_labels,
      );
      return getSummaryResult(summary);
    },
  });
}
