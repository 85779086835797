import React from 'react';
import { cn } from '@/helpers/util';
import {
  MessageCircle, Users, Sigma, DollarSign,
} from 'lucide-react';
import SentimentGraph from '@/components/bucket/SentimentGraph';
import { useParams } from 'react-router-dom';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import useTopic from '@/components/bucket/hooks/useTopic';
import useSubtopic from '@/components/bucket/hooks/useSubtopic';
import {
  getCsatAvg, getNpsAvg, getSentimentsAvg, mrrFormatter,
} from '@/components/bucket/lib/utils';
import { Spin } from 'antd';
import ScoreChangeMenu from '@/components/bucket/ScoreChangeMenu';
import { useFilters } from '@/components/filter/context';
import { transformCustomFieldToGet } from '@/api/conversations';

const getAvgList = (data) => {
  if (!data) {
    return [];
  }

  switch (data.score_type) {
    case 'sentiment': return getSentimentsAvg(data.sentiment);
    case 'nps': return getNpsAvg(data.nps);
    case 'csat': return getCsatAvg(data.csat);
    default: return [];
  }
};

const getScore = (data) => {
  if (!data) {
    return 0;
  }

  switch (data.score_type) {
    case 'sentiment':
      if (!data.sentiment) {
        return 0;
      }
      return (data.sentiment.positive - data.sentiment.negative) * 100;
    case 'nps':
      if (!data.nps) {
        return 0;
      }
      return (data.nps.promoters - data.nps.detractors) * 100;
    case 'csat':
      if (!data.csat) {
        return 0;
      }
      return data.csat.satisfied * 100;
    default: return 0;
  }
};

const OverallScore = ({ className }) => {
  const { topicId, subtopicId } = useParams();
  const { defaultDate } = useDefaultDateStore();
  const { filters } = useFilters();
  const { data: topic, refetch: refetchTopic } = useTopic(topicId, {
    start: defaultDate.start,
    end: defaultDate.end,
    keywords: filters.included_keywords,
    sentiment: filters.sentiment,
    labels: filters.labels ? filters.labels.map((label) => {
      if (typeof label === 'object') {
        return label.id;
      }

      return label;
    }) : undefined,
    exclude_labels: filters.exclude_labels,
    excluded_keywords: filters.excluded_keywords,
    sources: JSON.stringify(filters.sources),
    ...transformCustomFieldToGet(filters.custom_fields),
  });
  const { data: subtopic, refetch: refetchSubtopic } = useSubtopic(subtopicId, {
    start: defaultDate.start,
    end: defaultDate.end,
    keywords: filters.included_keywords,
    sentiment: filters.sentiment,
    labels: filters.labels ? filters.labels.map((label) => {
      if (typeof label === 'object') {
        return label.id;
      }

      return label;
    }) : undefined,
    exclude_labels: filters.exclude_labels,
    excluded_keywords: filters.excluded_keywords,
    sources: JSON.stringify(filters.sources),
    ...transformCustomFieldToGet(filters.custom_fields),
  });

  const data = subtopic || topic;
  const refetch = subtopic ? refetchSubtopic : refetchTopic;
  const avgList = getAvgList(data);

  return (
    <section className={cn('px-5 py-4 font-[Gordita-Regular] text-[#44596C] border-0 border-r border-solid border-[#E5ECF3]', className)}>
      {
        !data ? (
          <div className="w-full h-full z-10 flex justify-center items-center">
            <Spin />
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <span className="text-sm">Overall score</span>
              <ScoreChangeMenu topic={topic} data={data} refetch={refetch} />
            </div>
            <div className="flex gap-[8px] ml-4">
              <SentimentGraph
                data={avgList}
                style={{ marginTop: 40 }}
                ratio={data.ratio}
                score={getScore(data)}
                type={data.score_type}
              />
              <div style={{ marginTop: 40 }} className="flex flex-col gap-[32px]">
                <div className="table border-spacing-2">
                  {
                      avgList.map((entry) => (
                        <div className="table-row">
                          <div className="table-cell align-middle">
                            <div style={{ backgroundColor: entry.graphColor }} className={cn('w-[8px] h-[8px] rounded-full')} />
                          </div>
                          <div className="table-cell">
                            {entry.name}
                          </div>
                          <div className="table-cell">
                            <div className="text-[#202324] font-[Gordita-Medium]">
                              {entry.value}
                              %
                            </div>
                          </div>
                        </div>
                      ))
                    }
                </div>
                <div className="flex text-[12px]">
                  <div className="table border-spacing-2">
                    <div className="table-row">
                      <div className="table-cell align-middle">
                        <MessageCircle size={12} strokeWidth={1.25} />
                      </div>
                      <div className="table-cell">
                        Feedback
                      </div>
                      <div className="table-cell">
                        <div className="font-[Gordita-Medium]">{data.count.value}</div>
                      </div>
                    </div>
                    {
                        data.users ? (
                          <div className="table-row">
                            <div className="table-cell align-middle">
                              <Users size={12} strokeWidth={1.25} />
                            </div>
                            <div className="table-cell">
                              Users
                            </div>
                            <div className="table-cell">
                              <div className="font-[Gordita-Medium]">{data.users.count}</div>
                            </div>
                          </div>
                        ) : null
                      }
                    <div className="table-row">
                      <div className="table-cell align-middle">
                        <Sigma size={12} strokeWidth={1.25} />
                      </div>
                      <div className="table-cell">
                        Ratio
                      </div>
                      <div className="table-cell">
                        <div className="font-[Gordita-Medium]">
                          {Math.ceil(data.ratio.value * 100)}
                          %
                        </div>
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell align-middle">
                        <DollarSign size={12} strokeWidth={1.25} />
                      </div>
                      <div className="table-cell">
                        MRR
                      </div>
                      <div className="table-cell">
                        <div className="font-[Gordita-Medium]">{mrrFormatter(data.mrr.value)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </section>
  );
};

export default OverallScore;
