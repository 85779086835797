import { useMemo } from 'react';
import useURLState from '@/hooks/useUrlState';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';

const useFilters = () => {
  // Range filters

  const { defaultDate } = useDefaultDateStore();
  const { urlQueryState, setUrlQueryParams } = useURLState({
    conversations: undefined,
    topics: undefined,
    mrr: undefined,
    search: '',
    name: defaultDate.name,
    renewal: undefined,
    userType: undefined,
    sentiment: 'all',
    sentimentIntensity: 'over',
    sentimentIntensityPercentage: 50,
  });

  const filters = useMemo(
    () => ({
      name: urlQueryState.search ? urlQueryState.search : '',
      start: defaultDate.start,
      end: defaultDate.end,
      renewal: urlQueryState.renewal,
      userType: urlQueryState.userType,
      sentiment: urlQueryState.sentiment ? urlQueryState.sentiment : 'all',
      sentimentIntensity: urlQueryState.sentimentIntensity
        ? urlQueryState.sentimentIntensity
        : 'over',
      sentimentIntensityPercentage: urlQueryState.sentimentIntensityPercentage
        ? urlQueryState.sentimentIntensityPercentage
        : 50,
    }),
    [urlQueryState],
  );

  return {
    sorts: urlQueryState,
    setSort: (name, value) => {
      setUrlQueryParams({
        conversations: undefined,
        topics: undefined,
        mrr: undefined,
        page: 1,
        [name]: value,
      });
    },
    search: urlQueryState.search,
    setSearch: (value) => {
      setUrlQueryParams({
        page: 1,
        search: value,
      });
    },
    rangeFilter: defaultDate,
    setRangeFilter: (values) => setUrlQueryParams({
      page: 1,
      ...values,
    }),
    tableFilters: {
      renewal: urlQueryState.renewal,
      userType: urlQueryState.userType,
      sentiment: urlQueryState.sentiment ? urlQueryState.sentiment : 'all',
      sentimentIntensity: urlQueryState.sentimentIntensity
        ? urlQueryState.sentimentIntensity
        : 'over',
      sentimentIntensityPercentage: urlQueryState.sentimentIntensityPercentage
        ? urlQueryState.sentimentIntensityPercentage
        : 50,
    },
    setTableFilter: (name, value) => {
      setUrlQueryParams({
        page: 1,
        [name]: value,
      });
    },
    filters,
  };
};

export default useFilters;
