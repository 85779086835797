export default (state = null, action) => {
  switch (action.type) {
    case 'USER_AUTHENTICATED':
      return action.user;
    case 'CONNECT_INTERCOM':
      return {
        ...state,
        has_intercom_platform: true,
      };
    case 'CONNECT_ZENDESK':
      return {
        ...state,
        has_zendesk_platform: true,
      };
    default:
      return state;
  }
};
