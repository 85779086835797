import React from 'react';
import { FileSpreadsheet } from 'lucide-react';
import { FaIntercom, FaHubspot } from 'react-icons/fa';
import { SiZendesk } from 'react-icons/si';
import {
  CSVContainer, HubspotContainer, IntercomContainer, ZendeskContainer,
} from '../integrations';

export function getPlatformImage(platform) {
  switch (platform) {
    case 'zendesk':
      return <SiZendesk size={21} color="#262626" />;
    case 'intercom':
      return <FaIntercom size={24} color="#286EFA" />;
    case 'csv':
      return <FileSpreadsheet size={24} fill="#FFFFFF" color="#A9BCCF" />;
    case 'hubspot':
      return <FaHubspot size={21} color="#FF7A59" />;
    default:
      return null;
  }
}

export function getPlatformRender(platform) {
  switch (platform) {
    case 'intercom':
      return <IntercomContainer />;
    case 'zendesk':
      return <ZendeskContainer />;
    case 'csv':
      return <CSVContainer />;
    case 'hubspot':
      return <HubspotContainer />;
    default:
      return null;
  }
}

export const platforms = [
  {
    name: 'Intercom',
    image: <FaIntercom size={24} color="#286EFA" />,
    category: 'Chat',
    trial: true,
  },
  {
    name: 'Zendesk',
    image: <SiZendesk size={21} color="#262626" />,
    category: 'Support',
    trial: true,
  },
  // {
  //   name: 'CSV',
  //   image: <FileSpreadsheet size={24} fill="#FFFFFF" color="#A9BCCF" />,
  //   category: 'Feedback Import',
  //   trial: true,
  // },
  {
    name: 'Hubspot',
    image: <FaHubspot size={21} color="#FF7A59" />,
    category: 'CRM',
    trial: false,
  },
];
