import React from 'react';
import { Checkbox } from 'antd';

export const renewalOptions = [{
  option: '1W',
  label: '1 week',
}, {
  option: '2W',
  label: '2 weeks',
}, {
  option: '1M',
  label: '1 month',
}, {
  option: '3M',
  label: '3 months',
}, {
  option: '6M',
  label: '6 months',
}, {
  option: '1Y',
  label: '1 year',
}];

const getDecodedValue = (encodedValue) => {
  if (encodedValue === 'undefined' || encodedValue === undefined) return [];
  return JSON.parse(encodedValue);
};

const RenewalFilter = ({ filterValue: encodedFilterValue, setTableFilter, confirm }) => {
  const filterValue = getDecodedValue(encodedFilterValue);
  const filterRenewal = (confirmFilter, renewal) => {
    let newValue = filterValue;
    if (filterValue.includes(renewal)) {
      newValue = newValue.filter((item) => item !== renewal);
    } else {
      newValue.push(renewal);
    }

    setTableFilter('renewal', newValue.length > 0 ? JSON.stringify(newValue) : undefined);
    confirmFilter({ closeDropdown: true });
  };

  return (
    <div className="renewal-filter">
      {renewalOptions.map(({ option, label }) => (
        <Checkbox
          key={option}
          checked={filterValue.includes(option)}
          onChange={() => filterRenewal(confirm, option)}
        >
          {label}
        </Checkbox>
      ))}
    </div>
  );
};

export default RenewalFilter;
