import { subtopics, topics } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import { useModalStore } from '@/store/zustand/useModalStore';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Dropdown, Menu, message, Modal,
} from 'antd';
import {
  MailCheckIcon, MailXIcon, MoreVerticalIcon, PenLineIcon, Trash2Icon,
} from 'lucide-react';
import React from 'react';

function AnomalySubscribe({ topicId, topic, ...props }) {
  const [enabled, setEnabled] = React.useState(topic ? topic.anomaly_subscribed : false);
  const [loading, setLoading] = React.useState(false);
  const handleClick = React.useCallback(() => {
    setLoading(true);
    setEnabled(!enabled);

    topics.updateAnomalySubscription(topicId, !enabled ? 'subscribe' : 'unsubscribe').then(() => {
      setLoading(false);
    }).catch((e) => {
      setLoading(false);
      displayOrCatchMessage(e);
    });
  }, [topicId, enabled]);

  return topic ? (
    <Menu.Item {...props} disabled={loading} className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]" onClick={handleClick}>
      {
        !enabled
          ? <MailCheckIcon size={16} strokeWidth={2} />
          : <MailXIcon size={16} strokeWidth={2} />
      }
      <span>
        {enabled ? 'Disable' : 'Enable'}
        {' '}
        anomaly emails
      </span>
    </Menu.Item>
  ) : null;
}

export function TaxonomyTableAction({ record, topic }) {
  const queryClient = useQueryClient();
  const { setModal } = useModalStore();

  const deleteTopic = useMutation({
    mutationKey: 'topic_delete',
    mutationFn: async () => {
      await topics.deleteTopic(record.id);
    },
    onSuccess: () => {
      message.success('Topic deleted successfully');
    },
    onError: () => {
      message.error('Failed deleting topic');
    },
  });

  const deleteSubtopic = useMutation({
    mutationKey: 'subtopic_delete',
    mutationFn: async () => {
      await subtopics.deleteSubtopic(record.id, false);
    },
    onSuccess: () => {
      message.success('Subtopic deleted successfully');
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['subtopics'] });
    },
    onError: () => {
      message.error('Failed deleting subtopic');
    },
  });

  const handleDeleteModal = () => {
    Modal.confirm({
      title: `Are you sure you want to delete ${topic ? 'subtopic' : 'topic'}?`,
      okText: 'Delete',
      cancelText: 'Cancel',
      okType: 'primary',
      cancelType: 'default',
      onOk: () => {
        if (topic) {
          message.loading('Subtopic is deleting');
          deleteSubtopic.mutate();
          return;
        }

        message.loading('Topic is deleting');
        deleteTopic.mutate();
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  return ((!record.has_children && 'groups' in record) || topic) ? (
    <div className="opacity-0 flex items-center">
      <Dropdown
        className="cursor-pointer"
        placement="bottomRight"
        trigger={['click']}
        overlay={(
          <Menu className="!rounded-md shadow-[0px_1px_12px_#CAD7E180] border border-solid border-[#DFE9F1] font-[Gordita-Regular]">
            <Menu.Item
              className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]"
              onClick={() => setModal('edit_modal', {
                ...record,
                topicId: topic ? topic.id : record.id,
                subtopicId: topic ? record.id : null,
              })}
            >
              <PenLineIcon size={16} strokeWidth={2} />
              <span>
                Edit
                {' '}
                {topic ? 'Subsegment' : 'Segment'}
              </span>
            </Menu.Item>
            <Menu.Item
              className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]"
              onClick={() => setModal('edit_releases_modal', topic ? topic.id : record.id)}
            >
              <PenLineIcon size={16} strokeWidth={2} />
              <span>
                Edit Releases
              </span>
            </Menu.Item>
            <AnomalySubscribe topic={topic || record} topicId={topic ? topic.id : record.id} />
            <Menu.Item className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]" onClick={handleDeleteModal}>
              <Trash2Icon size={16} strokeWidth={2} />
              <span>Delete</span>
            </Menu.Item>
          </Menu>
        )}
      >
        <MoreVerticalIcon className="hover:stroke-[#44596C]" color="#8F9DA9" size={20} />
      </Dropdown>
    </div>
  ) : null;
}
