import { Tabs } from 'antd';
import { Link, useParams } from 'react-router-dom';
import React from 'react';
import SentimentLanguageTab from '@/components/settings/SentimentLanguageTab';
import ExclusionsTab from './ExclusionTab';
import CustomFieldsTab from './CustomFieldsTab';
import LabelsTab from './LabelsTab';
import NotificationsTab from './NotificationsTab';

const { TabPane } = Tabs;

const getActiveKey = (tab) => {
  switch (tab) {
    case 'exclude-conversations': return 'settings_exclusions';
    case 'exclude-people': return 'settings_exclusions';
    case 'exclude-labels': return 'settings_exclusions';
    case 'labels': return 'settings_labels';
    case 'custom-fields': return 'settings_custom_fields';
    case 'notifications': return 'settings_notifications';
    case 'advanced': return 'settings_advanced';
    default: return null;
  }
};

export const SettingsPage = () => {
  const { tab } = useParams();

  return (
    <div className="mx-md datatable-header-half datatable-header-half-tabs settings-tabs-wrapper">
      <Tabs activeKey={getActiveKey(tab)}>
        <TabPane
          tab={<Link to="/settings/exclude-conversations">Exclusions</Link>}
          key="settings_exclusions"
        >
          <ExclusionsTab />
        </TabPane>
        <TabPane
          tab={<Link to="/settings/labels">Labels</Link>}
          key="settings_labels"
        >
          <LabelsTab />
        </TabPane>
        <TabPane
          tab={<Link to="/settings/custom-fields">Custom fields</Link>}
          key="settings_custom_fields"
        >
          <CustomFieldsTab />
        </TabPane>
        <TabPane
          tab={<Link to="/settings/notifications">Notifications</Link>}
          key="settings_notifications"
        >
          <NotificationsTab />
        </TabPane>
        <TabPane
          tab={<Link to="/settings/advanced">Advanced</Link>}
          key="settings_advanced"
        >
          <SentimentLanguageTab />
        </TabPane>
      </Tabs>
    </div>
  );
};
