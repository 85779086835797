import React from 'react';
import { Helmet } from 'react-helmet';
import DiscoveryContainer from '@/components/discovery/DiscoveryContainer';
import { SentimentDateFilter } from '@/components/ui/sentiment-date-filter';
import ExportButton from '@/components/ui/export-button';
import Layout from '@/components/Layout';
import { useFiltersStore } from '@/store/zustand/useFiltersStore';
import { FiltersProvider } from '@/components/filter/context';

export default function DiscoveryPage() {
  const { filters } = useFiltersStore();

  const getAppliedFilters = () => {
    const defaultFilters = ['included_keywords', 'sentiment', 'custom_fields', 'excluded_keywords', 'labels', 'sources'];
    const appliedFilters = Object.keys(filters).filter((key) => defaultFilters.includes(key) && filters[key].length > 0);

    if (appliedFilters.length === 0) return ['included_keywords', 'sentiment', 'custom_fields'];
    return appliedFilters;
  };

  return (
    <Layout
      rightHeader={(
        <>
          <SentimentDateFilter />
          <ExportButton filename="clientzen-discovery-export" />
        </>
      )}
    >
      <Helmet titleTemplate="%s - ClientZen" title="Discovery" />
      <FiltersProvider value={{ filters, selectedFilters: getAppliedFilters() }}>
        <DiscoveryContainer />
      </FiltersProvider>
    </Layout>
  );
}
