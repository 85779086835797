/* eslint-disable no-unused-vars */
import React from 'react';
import { Empty } from 'antd';
import { cn } from '@/helpers/util';
import SentimentAndCountRow from './SentimentAndCountRow';
import Card from '../card';

const SentimentAndCountLines = ({
  name, extra, color, hasPercentages, data, total, onRowClick, className,
}) => {
  const graphTotal = data.length > 0 ? (
    parseInt(data[0].negative_count, 10)
    + parseInt(data[0].positive_count, 10)
    + parseInt(data[0].neutral_count, 10)
  ) : 0;

  return (
    <Card className={cn('sentiment-and-count-lines py-[18px] mb-4', className)}>
      <Card.Header>
        <div className="flex items-center divide-y-0 divide-x-2 gap-7 divide-solid divide-[#DFE9F1]">
          <Card.Header.Title title={name} />
        </div>
        <Card.Header.Link to="/people" />
      </Card.Header>
      <Card.Body className="!mt-6 sentiment-and-count-lines-body">
        {
          !data.length && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data over the selected period" />
          )
        }
        {
          data.map((row) => {
            const negative = parseInt(row.negative_count, 10);
            const positive = parseInt(row.positive_count, 10);
            const neutral = parseInt(row.neutral_count, 10);
            const count = negative + positive + neutral;
            const totalConv = parseInt(total, 10);
            const percentage = totalConv > 0 ? (count / totalConv) * 100 : 0;
            const graphPercentage = graphTotal > 0 ? (count / graphTotal) * 100 : 0;

            return (
              <SentimentAndCountRow
                key={row.id ? row.id : row.name}
                hasPercentages={hasPercentages}
                name={row.name}
                sentiment={{
                  positive: count > 0 ? positive / count : 0,
                  negative: count > 0 ? negative / count : 0,
                }}
                count={count}
                percent={percentage.toFixed(0)}
                width={graphPercentage}
                color={color}
                onRowClick={onRowClick ? onRowClick(row) : null}
              />
            );
          })
        }
      </Card.Body>
    </Card>
  );
};

export default SentimentAndCountLines;
