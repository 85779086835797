import { useModalStore } from '@/store/zustand/useModalStore';
import { Button, Dropdown, Menu } from 'antd';
import { PenBoxIcon } from 'lucide-react';
import React from 'react';

export default function CreateDropdown() {
  const { setModal } = useModalStore();
  return (
    <Dropdown
      className="cursor-pointer"
      placement="bottomRight"
      trigger={['click']}
      overlay={(
        <Menu className="!rounded-md shadow-[0px_1px_12px_#CAD7E180] border border-solid border-[#DFE9F1] font-[Gordita-Regular]">
          <Menu.Item className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]" onClick={() => setModal('create_taxonomy_category')}>
            <span>Create Category</span>
          </Menu.Item>
          <Menu.Item className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]" onClick={() => setModal('create_taxonomy_topic')}>
            <span>Create Segment</span>
          </Menu.Item>
        </Menu>
        )}
    >
      <Button
        className="flex items-center justify-center"
        icon={<PenBoxIcon size={16} />}
        type="primary"
      />
    </Dropdown>
  );
}
