/* eslint-disable */
import { useEffect } from 'react';
import useStateWithCallback from 'use-state-with-callback';

const LOADING_STATE = Object.freeze({
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
});

const initialState = {
  data: undefined,
  error: undefined,
  loading: LOADING_STATE.IDLE,
};

const useApi = (
	fetcher,
	{ runOnMount, onSuccess, onSuccessAsync, onFailure, onFailureAsync } = {
		ronOnMount: false,
		onSuccess: undefined,
		onSuccessAsync: undefined,
		onFailure: undefined,
		onFailureAsync: undefined,
	}
) => {
	const [state, setState] = useStateWithCallback(initialState, (state) => {
		switch (state.loading) {
			case LOADING_STATE.SUCCESS: {
				if (onSuccess) onSuccess(state);
				if (onSuccessAsync) {
					const runPromise = async () => await onSuccessAsync(state);
					runPromise();
				}
			}
			case LOADING_STATE.FAILED: {
				if (onFailure) onFailure(state);
				if (onFailureAsync) {
					const runPromise = async () => await onFailureAsync(state);
					runPromise();
				}
			}
			default: {
			}
		}
	});

	const setLoading = () =>
		setState((prevState) => ({
			...prevState,
			error: undefined,
			loading: LOADING_STATE.LOADING,
		}));

	const setSuccess = (data) =>
		setState((prevState) => ({
			...prevState,
			data,
            error: undefined,
			loading: LOADING_STATE.SUCCESS,
		}));

	const setFailure = (error) =>
		setState((prevState) => ({
			...prevState,
            data: undefined,
			error,
			loading: LOADING_STATE.FAILED,
		}));

	const run = async (...params) => {
		setLoading();
		try {
			const data = await fetcher(...params);
			setSuccess(data);
		} catch (error) {
			setFailure(error.toString());
		}
	};

	useEffect(() => {
		if (runOnMount) {
			run();
		}
	}, []);

	return {
		...state,
		run,
		isIdle: state.loading === LOADING_STATE.IDLE,
		isLoading: state.loading === LOADING_STATE.LOADING,
		isSuccess: state.loading === LOADING_STATE.SUCCESS,
		isFailed: state.loading === LOADING_STATE.FAILED,
	};
};

export default useApi;