import React, { useState } from 'react';
import { Button } from 'antd';
import { platforms } from '@/api';
import { displayOrCatchMessage } from '../../../helpers';
import useIntegrationsStore from '../store/useIntegrationsStore';

const StartInsightsDiscoveryButton = ({ type }) => {
  const {
    actions: {
      updateConnectedPlatformsWithResponse,
    },
  } = useIntegrationsStore();
  const [loading, setLoading] = useState(false);
  const onClick = () => {
    const apiEndpoint = type === 'intercom' ? platforms.activateIntercom : platforms.activateZendesk;

    setLoading(true);
    apiEndpoint().then(() => {
      platforms.index().then((response) => {
        updateConnectedPlatformsWithResponse(response);
        setLoading(false);
      }).catch((e) => {
        displayOrCatchMessage(e);
        setLoading(false);
      });
    }).catch((e) => {
      setLoading(false);
      displayOrCatchMessage(e);
    });
  };

  return (
    <Button
      type="primary"
      className="start-discovery-insights"
      onClick={onClick}
      loading={loading}
      size="large"
    >
      Start insights discovery
    </Button>
  );
};

export default StartInsightsDiscoveryButton;
