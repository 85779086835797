import axios from './axios';
import auth from './auth';

const all = () => axios
  .get('/api/dashboard', {
    params: {
      team_id: auth.team().id,
    },
  })
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const allNew = (start, end, search, week) => axios
  .get('/api/dashboard-new', {
    params: {
      team_id: auth.team().id,
      start,
      end,
      search: search || undefined,
      week,
    },
  })
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const ready = () => axios
  .get('/api/dashboard-ready', {
    params: {
      team_id: auth.team().id,
    },
  })
  .then((response) => response.data.data)
  .catch((e) => Promise.reject(e));

const highlighted = () => axios
  .get('/api/dashboard-highlighted', {
    params: {
      team_id: auth.team().id,
    },
  })
  .then((response) => response.data.data)
  .catch((e) => Promise.reject(e));

const trends = () => axios
  .get('/api/dashboard-trends', {
    params: {
      team_id: auth.team().id,
    },
  })
  .then((response) => response.data.data)
  .catch((e) => Promise.reject(e));

const last7DaysScores = () => axios
  .get('/api/dashboard-last-7-days-scores', {
    params: {
      team_id: auth.team().id,
    },
  })
  .then((response) => response.data.data)
  .catch((e) => Promise.reject(e));

const last30DaysScores = () => axios
  .get('/api/dashboard-last-30-days-scores', {
    params: {
      team_id: auth.team().id,
    },
  })
  .then((response) => response.data.data)
  .catch((e) => Promise.reject(e));

const averageSentiment = ({ start, end }) => axios
  .get('/api/dashboard-average-sentiment-count', {
    params: {
      team_id: auth.team().id,
      start,
      end,
    },
  })
  .then((response) => response.data.data)
  .catch((e) => Promise.reject(e));

const dashboard = {
  all,
  allNew,
  ready,
  highlighted,
  trends,
  last7DaysScores,
  last30DaysScores,
  averageSentiment,
};

export default dashboard;
