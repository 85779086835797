/* eslint-disable */
import React, { useState } from 'react';
import { Button, Checkbox, Select, Spin, Typography } from 'antd';

const sentimentOptions = [
	{
		label: 'All',
		value: 'all',
	},
	{
		label: 'Positive',
		value: 'positive',
	},
	{
		label: 'Negative',
		value: 'negative',
	},
	{
		label: 'Neutral',
		value: 'neutral',
	},
];

const sentimentIntensityOptions = [
	{
		label: 'Over',
		value: 'over',
	},
	{
		label: 'Under',
		value: 'under',
	},
];

const sentimentIntensityPercentageOptions = [
	{
		label: '25%',
		value: '25',
	},
	{
		label: '50%',
		value: '50',
	},
	{
		label: '75%',
		value: '75',
	},
	{
		label: '100%',
		value: '100',
	},
];

const SentimentFilter = ({ sentiment, sentimentIntensity, sentimentIntensityPercentage, setTableFilter, confirm }) => {
	const [localFilters, setLocalFilters] = useState({
		sentiment,
		sentimentIntensity,
		sentimentIntensityPercentage,
	});

	const saveLocalFilters = () => {
		setTableFilter('sentiment', localFilters.sentiment);
		setTableFilter('sentimentIntensity', localFilters.sentimentIntensity);
		setTableFilter('sentimentIntensityPercentage', localFilters.sentimentIntensityPercentage);
		confirm({ closeDropdown: true });
	};

	return (
		<div className='sentiment-filter'>
			<Typography className='sentiment-label'>Sentiment</Typography>
			<Select
				options={sentimentOptions}
				defaultValue={sentiment}
				size='small'
				value={localFilters.sentiment}
				onChange={(value) => setLocalFilters({ ...localFilters, sentiment: value })}
			/>
			<Typography className='intensity-label'>Intensity</Typography>
			<div style={{ display: 'flex' }}>
				<Select
					options={sentimentIntensityOptions}
					defaultValue={sentimentIntensity}
					size='small'
					value={localFilters.sentimentIntensity}
					onChange={(value) => setLocalFilters({ ...localFilters, sentimentIntensity: value })}
				/>
				<Select
					options={sentimentIntensityPercentageOptions}
					defaultValue={sentimentIntensityPercentage}
					size='small'
					value={localFilters.sentimentIntensityPercentage}
					onChange={(value) => setLocalFilters({ ...localFilters, sentimentIntensityPercentage: value })}
				/>
			</div>
			<div className='footer'>
				<Button className='save-btn' type='primary' size='small' onClick={saveLocalFilters}>
					Save
				</Button>
				<Button className='cancel-btn' size='small' onClick={() => confirm({ closeDropdown: true })}>
					Cancel
				</Button>
			</div>
		</div>
	);
};

export default SentimentFilter;
