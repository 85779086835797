import { Divider, Tooltip } from 'antd';
import { ArrowDown, ArrowUp } from 'lucide-react';
import React from 'react';

export default function FeedbackCount({ value, trend, percent }) {
  return (
    <div className="flex items-center gap-x-1">
      <Tooltip title="The ammount of feedback in the current period" placement="bottom">
        <span className="block text-[#202324] font-[Gordita-Medium]">
          {value}
        </span>
      </Tooltip>
      <Divider
        type="vertical"
        className="[&.ant-divider-vertical]:border-[#CEDBE4] [&.ant-divider-vertical]:h-5"
      />
      <Tooltip title="Change in feedback compared to the previous period" placement="bottom">
        <div className="flex items-center gap-x-1">
          {trend === 'ascending' ? (
            <ArrowUp size={16} strokeWidth={2.5} color="#F17E99" />
          ) : (
            <ArrowDown size={16} strokeWidth={2.5} color="#A8BCCF" />
          )}
          <span className="text-[#75899B] text-[12px] leading-6 w-12">
            {trend === 'ascending' ? '+' : '-'}
            {percent}
            %
          </span>
        </div>
      </Tooltip>
    </div>
  );
}
