import axios from './axios';
import auth from './auth';

const setHasConversationMarking = (hasConversationMarking) => axios.put(
  `/api/teams/${auth.team().id}/conversation-marking`,
  {
    has_conversation_marking: hasConversationMarking,
  }, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const setWeeklyReportEnabled = (weeklyReportEnabled) => axios.put(
  '/api/weekly-report-enabled',
  {
    weekly_report_enabled: weeklyReportEnabled,
  }, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const setComprehendLanguage = (language) => axios.put(
  `/api/teams/${auth.team().id}/comprehend-language`,
  {
    comprehend_language: language,
  }, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const putOnboardingQuestions = (questions) => axios.put('/api/teams/onboarding-questions', {
  team_id: auth.team().id,
  ...questions,
})
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const teams = {
  setHasConversationMarking,
  setWeeklyReportEnabled,
  setComprehendLanguage,
  putOnboardingQuestions,
};

export default teams;
